import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import { useTheme } from "@mui/material"
import { JProject, JServerExtent } from "jmapcloud-types"
import { windowCoords } from "map/model"
import { getExtentFromMapWindow } from "map/utils"
import DrawRectangle from "mapbox-gl-draw-rectangle-mode"
import maplibregl, { IControl, Map as MaplibreMap } from "maplibre-gl"
import proj4 from "proj4"
import React from "react"
import { Layer, Map, Source } from "react-map-gl/maplibre"

export interface SelectExtentMapProps {
  project: JProject
  setExtent: (extent: JServerExtent | null) => void
  windowCoords: windowCoords
  initialExtent: JServerExtent | null
}
export const SelectExtentMap = (props: SelectExtentMapProps) => {
  const [map, setMap] = React.useState<MaplibreMap | null>(null)
  const drawRef = React.useRef<MapboxDraw | null>(null)
  const theme = useTheme()

  // necessary for async calls in useEffects
  const mounted = React.useRef(false)
  React.useEffect(() => {
    mounted.current = true
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      modes,
      styles: extendStyle
    })
    return () => {
      mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (!map || !mounted.current) {
      return
    }

    map.addControl(drawRef.current as unknown as IControl)
    drawInitialExtent()
    map.on("zoomend", updateExtent)
    map.on("moveend", updateExtent)
  }, [map])

  // when the window coords change, update the extent (eg. when the window is resized)
  React.useEffect(() => {
    updateExtent()
  }, [props.windowCoords])

  return (
    <Map
      style={{ width: "100%", height: "100%", backgroundColor: theme.palette.background.default }}
      id="sds-map"
      renderWorldCopies={false}
      mapLib={maplibregl}
      onLoad={e => {
        setMap(e.target)
      }}
    >
      <Source
        id="basemap"
        type="raster"
        tileSize={256}
        maxzoom={19}
        tiles={["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"]}
      >
        <Layer type="raster" id="basemap" />
      </Source>
    </Map>
  )

  function drawInitialExtent() {
    if (map && drawRef.current && props.initialExtent) {
      const initialExtent = props.initialExtent
      const transform = proj4(props.project.mapCrs, "EPSG:4326")

      const extent4326 = [
        transform.forward([initialExtent.x1, initialExtent.y1]),
        transform.forward([initialExtent.x1, initialExtent.y2]),
        transform.forward([initialExtent.x2, initialExtent.y2]),
        transform.forward([initialExtent.x2, initialExtent.y1]),
        transform.forward([initialExtent.x1, initialExtent.y1])
      ]
      const extent: GeoJSON.Feature<GeoJSON.Polygon> = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [extent4326]
        }
      }

      drawRef.current.deleteAll().add(extent)
      map.fire("draw.create", { features: [extent] })
      map.fitBounds(
        [
          [extent4326[0][0], extent4326[0][1]],
          [extent4326[2][0], extent4326[2][1]]
        ],
        { animate: true, padding: 30, maxZoom: 18 }
      )
    }
  }

  function updateExtent() {
    if (map) {
      props.setExtent(getExtentFromMapWindow(map, props.windowCoords))
    }
  }
}

const modes: any = MapboxDraw.modes
modes.draw_rectangle = DrawRectangle

const extendStyle = [
  {
    id: "gl-draw-polygon-stroke",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "line-color": "#555555",
      "line-width": 2,
      "line-dasharray": [1, 1]
    }
  }
]
