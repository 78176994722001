import { ajaxSVC } from "app/ajax/service"
import { translate } from "app/language/service"
import { TOKEN_LOCAL_STORAGE } from "app/model"
import { store } from "app/store/store"
import { messageSVC } from "message/service"
import { JOrganization } from "organization/model"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById, getNgBaseUrl } from "server/tools/common"
import { userCFG } from "user/config"
import { userEventModule } from "user/event"
import { EMPTY_TOKEN_VALUE, JTokenInfo, JUser, ROLES, USER_EVENTS } from "user/model"
import userRPO from "user/repository"
import { endLogin, initialUserState, selectUserOrganization, setUserInfo, setUserOrganizations, startLogin } from "user/store"
import { auth0Login, clearAuth0Session, getTokenInfo, initAuth0Client, keepTokenValid, revokeRefreshToken } from "./auth0"
import { rolesIncludeMinimumMemberRole } from "./permissions"

export function getUsername(): string {
  return store.getState().user.name
}

export function getUserOrganization(): JOrganization {
  // This cannot be called in a context where the user org is not selected
  return store.getState().user.selectedOrganization!
}

export function getAccessToken(): string {
  return store.getState().user.accessToken
}

export function logout(): Promise<void> {
  return new Promise<void>(resolve => {
    revokeRefreshToken(false)
      .then(() => {
        startSessionTimer(initialUserState)
        resolve()
      })
      .catch((error: any) => {
        console.error("logout failed", error)
        startSessionTimer(initialUserState)
        resolve()
      })
      .finally(() => userEventModule.notify(USER_EVENTS.LOGOUT, {}))
  })
}

export function getUserById(userId: string): Promise<JUser> {
  return userRPO.getUserById(userId)
}

function startSessionTimer(userInfo: JUser & JTokenInfo): void {
  try {
    clearTimeout(userCFG.sessionKeeperTimeout)
    store.dispatch(setUserInfo(userInfo))
    if (userInfo.accessToken !== EMPTY_TOKEN_VALUE) {
      // Set the refresh delay slightly smaller than the expiration, just to be safe
      keepTokenValid(userInfo.accessTokenExpirationInMSecs - 60 * 1000)
    } else {
      localStorage.removeItem(TOKEN_LOCAL_STORAGE)
    }
  } catch (ex) {
    console.error("Unexpected error while initializing the project data", ex)
  }
}

export async function initSessionFromToken(): Promise<void> {
  store.dispatch(startLogin())
  try {
    await initAuth0Client()

    const tokenInfo: JTokenInfo | undefined = await getTokenInfo()

    if (!tokenInfo) {
      console.warn("Not logged in")
      store.dispatch(endLogin({ success: false }))
      await auth0Login()
      return
    }

    let user: JUser
    try {
      user = await userRPO.getUserIdentity(tokenInfo.accessToken)
    } catch (ex) {
      store.dispatch(endLogin({ success: false, userError: translate("user.login.error.unknown") }))
      throw ex
    }

    if (user.organizations.length === 0) {
      store.dispatch(endLogin({ success: false, userError: translate("user.login.error.no.organization") }))
      return
    }

    store.dispatch(setUserOrganizations(user.organizations))

    const auth0OrganizationId = tokenInfo.idToken.org_id
    const selectedAuth0Organization = auth0OrganizationId ? user.organizations.findByProperty("auth0Id", auth0OrganizationId) : undefined
    if (auth0OrganizationId && selectedAuth0Organization) {
      // already logged in an organization
      // need to fetch member's roles
      ajaxSVC
        .get({
          url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${selectedAuth0Organization.id}/members/${user.email}`,
          accessToken: tokenInfo.accessToken
        })
        .then(response2 => {
          user.roles = user.roles.concat(response2.result.roles as ROLES[])

          if (!rolesIncludeMinimumMemberRole(user.roles, ROLES.ORG_EDITOR)) {
            store.dispatch(
              endLogin({
                success: true, // the login is successful, even though the org cannot be accessed
                organizationError: translate("user.login.error.insufficient.org.permissions", {
                  organizationName: selectedAuth0Organization.name
                })
              })
            )
            if (user.roles.includes(ROLES.ORG_VIEWER)) {
              // Is org viewer: offer to redirect to NG
              messageSVC.confirmDialog({
                title: translate("user.login.error.insufficient.org.permissions", {
                  organizationName: selectedAuth0Organization.name
                }),
                message: translate("user.login.error.ng.redirect.offer"),
                confirmButtonLabel: translate("button.yes"),
                cancelButtonLabel: translate("button.no"),
                onSuccess: () => {
                  window.open(`${getNgBaseUrl()}?ngOrganizationId=${selectedAuth0Organization.id}`, "_blank")
                }
              })
            }
            return
          }

          store.dispatch(selectUserOrganization(selectedAuth0Organization))

          initAuth0Client(selectedAuth0Organization.auth0Id)
            .then(() => {
              const userInfo = { ...user, ...tokenInfo }
              startSessionTimer(userInfo)
              userEventModule.notify(USER_EVENTS.LOGIN, { userInfo })
              store.dispatch(endLogin({ success: true }))
            })
            .catch(error => {
              throw error
            })
        })
        .catch(error => {
          clearAuth0Session().then(() => store.dispatch(endLogin({ success: false, userError: translate("user.login.error.unknown") })))
        })
    } else {
      // authenticated, but not yet logged into an organization
      startSessionTimer({ ...user, ...tokenInfo })
      store.dispatch(endLogin({ success: true }))
    }
  } catch (ex) {
    await clearAuth0Session()
    store.dispatch(endLogin({ success: false, userError: translate("user.login.error.unknown") }))
    throw ex
  }
}

export async function logIntoOrganization(organization: JOrganization): Promise<void> {
  let user: JUser
  store.dispatch(startLogin())

  await userCFG.auth0Client.loginWithPopup({
    authorizationParams: {
      organization: organization.auth0Id
    }
  })

  const tokenInfo: JTokenInfo | undefined = await getTokenInfo()

  if (!tokenInfo) {
    console.warn("Not logged in,")
    store.dispatch(endLogin({ success: false, userError: translate("user.login.error.not.logged.in") }))
    return
  }

  try {
    user = await userRPO.getUserIdentity(tokenInfo.accessToken, organization)
  } catch (ex) {
    await clearAuth0Session()
    store.dispatch(endLogin({ success: false, userError: translate("user.login.error.unknown") }))
    return
  }

  if (user.organizations.length === 0) {
    store.dispatch(endLogin({ success: false, userError: translate("user.login.error.no.organization") }))
    return
  }

  if (!rolesIncludeMinimumMemberRole(user.roles, ROLES.ORG_EDITOR)) {
    store.dispatch(
      endLogin({
        success: true, // the login is successful, even though the org cannot be accessed
        organizationError: translate("user.login.error.insufficient.org.permissions", {
          organizationName: organization.name
        })
      })
    )
    if (user.roles.includes(ROLES.ORG_VIEWER)) {
      // Is org viewer: offer to redirect to NG
      messageSVC.confirmDialog({
        title: translate("user.login.error.insufficient.org.permissions", {
          organizationName: organization.name
        }),
        message: translate("user.login.error.ng.redirect.offer"),
        confirmButtonLabel: translate("button.yes"),
        cancelButtonLabel: translate("button.no"),
        onSuccess: () => {
          window.open(`${getNgBaseUrl()}?ngOrganizationId=${organization.id}`, "_blank")
        }
      })
    }
    return
  }

  // Here the best thing to do would be to reload the UI (with proper Redux state manipulations) but since the
  // org selection mechanism is likely to change at the auth0 level eventually, it's easier to simply reload
  // the app for now.
  location.reload()
}

export function getUserName(): string {
  return store.getState().user.name
}

function calculatePasswordStrength(password: string): number {
  // total score of password
  let passwordScore: number = 0
  if (password.length < 8) {
    return 0
  } else if (password.length >= 10) {
    passwordScore += 2
  } else {
    passwordScore += 1
  }
  // if it contains one digit, add 2 to total score
  if (password.match("(?=.*[0-9]).*")) {
    passwordScore += 2
  }
  // if it contains one lower case letter, add 2 to total score
  if (password.match("(?=.*[a-z]).*")) {
    passwordScore += 2
  }
  // if it contains one upper case letter, add 2 to total score
  if (password.match("(?=.*[A-Z]).*")) {
    passwordScore += 2
  }
  // if it contains one special character, add 2 to total score
  if (password.match("(?=.*[~!@#$%^&*()_-]).*")) {
    passwordScore += 2
  }
  return passwordScore
}
