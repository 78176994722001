import { Autocomplete, Box, TextField, Typography, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import React from "react"
import { ALL_ROLES, ROLES } from "user/model"

interface JRoleSelectProps {
  role: ROLES | null
  id?: string
  sx?: any
  required?: boolean
  placeholder?: string
  error?: string
  availableRoles?: ROLES[]
  onChange: (roles: ROLES[]) => any
  onBlur?: () => any
}

export const RoleSelect = (props: JRoleSelectProps): JSX.Element => {
  const sx = props.sx || {}
  const theme = useTheme()
  if (props.error) {
    sx["& label"] = { color: theme.palette.error.main }
  }

  return (
    <Box sx={sx}>
      <Autocomplete
        disablePortal
        size="small"
        id={props.id || "member-roles-select"}
        options={props.availableRoles || ALL_ROLES}
        value={props.role}
        fullWidth
        color={props.error ? "error" : undefined}
        onChange={(_event, newRole) => props.onChange(newRole === null ? [] : [newRole])}
        renderInput={params => (
          <TextField
            {...params}
            color={props.error ? "error" : undefined}
            label={`${translate("member.roles.select.label")}${props.required ? " *" : ""}`}
            placeholder={props.placeholder ? props.placeholder : translate("member.roles.select.placeholder")}
          />
        )}
        onBlur={() => props.onBlur && props.onBlur()}
        getOptionLabel={role => translate(`user.role.${role}`)}
      />
      {props.error && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            textAlign: "left",
            marginTop: ".1875rem !important",
            marginRight: ".875rem",
            marginBottom: 0,
            marginLeft: ".875rem"
          }}
          color={"error"}
        >
          {props.error}
        </Typography>
      )}
    </Box>
  )
}
