import { ajaxSVC } from "app/ajax/service"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import {
  JApiKey,
  JDASMetrics,
  JFUSMetrics,
  JMISMetrics,
  JOrganization,
  JOrganizationApiKeyCreateParams,
  JOrganizationInvitation,
  JOrganizationInvitationCreateParams,
  JOrganizationRepository,
  JOrganizationUpdateParams,
  JTag
} from "./model"

export const organizationRPO: JOrganizationRepository = {
  getOrganizationById(organizationId: string, accessToken?: string): Promise<JOrganization> {
    return new Promise<JOrganization>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}`,
            accessToken
          })
          .then((response: any) => resolve(getOrganizationFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while login", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while login", ex)
        reject(ex)
      }
    })
  },
  update(params: JOrganizationUpdateParams): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        ajaxSVC
          .patch({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${params.id}`,
            params
          })
          .then((response: any) => {
            resolve(response.result) // organization Id
          })
          .catch((error: any) => {
            console.error("Error while updating organization", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while updating organization", ex)
        reject(ex)
      }
    })
  },
  getApiKeys(organizationId: string): Promise<JApiKey[]> {
    return new Promise<JApiKey[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/apikeys`
          })
          .then(response => resolve(getApiKeysFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting organization's api keys", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting organization's api keys", ex)
        reject(ex)
      }
    })
  },
  getApiKey(organizationId: string, apiKeyId: string): Promise<JApiKey> {
    return new Promise<JApiKey>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/apikeys/${apiKeyId}`
          })
          .then(response => resolve(getApiKeyFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting organization's api key", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting organization's api key", ex)
        reject(ex)
      }
    })
  },
  createApiKey(organizationId: string, params: JOrganizationApiKeyCreateParams): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        const { title, expiresAt, roles } = params
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/apikeys`,
            params: {
              title,
              expiresAt,
              roles
            }
          })
          .then(response => resolve(response.result.apiKey))
          .catch((error: any) => {
            console.error("Error while creating organization's apikey", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while creating organization's apikey", ex)
        reject(ex)
      }
    })
  },
  deleteApiKey(organizationId: string, apiKeyId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/apikeys/${apiKeyId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error("Error while deleting organization's apikey", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while deleting organization's apikey", ex)
        reject(ex)
      }
    })
  },
  getOrganizationInvitations(organizationId: string): Promise<JOrganizationInvitation[]> {
    return new Promise<JOrganizationInvitation[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/invitations`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while getting organization's invitations", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting organization's invitations", ex)
        reject(ex)
      }
    })
  },
  getOrganizationInvitation(organizationId: string, invitationId: string): Promise<JOrganizationInvitation> {
    return new Promise<JOrganizationInvitation>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/invitations/${invitationId}`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while getting organization's invitation", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting organization's invitation", ex)
        reject(ex)
      }
    })
  },
  createOrganizationInvitation(organizationId: string, params: JOrganizationInvitationCreateParams): Promise<JOrganizationInvitation> {
    return new Promise<JOrganizationInvitation>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/invitations`,
            params: {
              inviteeEmail: params.inviteeEmail,
              locale: params.locale,
              roles: params.roles
            }
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while creating organization's invitation", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while creating organization's invitation", ex)
        reject(ex)
      }
    })
  },
  deleteOrganizationInvitation(organizationId: string, invitationId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/invitations/${invitationId}`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while deleting organization's invitation", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while deleting organization's invitation", ex)
        reject(ex)
      }
    })
  },
  getAllTags(organizationId: string): Promise<JTag[]> {
    return new Promise<JTag[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/tags`
          })
          .then((response: any) => resolve(response.result))
          .catch((error: any) => {
            console.error(`Server error while getting all tags of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all tags of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  getFUSMetrics(organizationId: string): Promise<JFUSMetrics> {
    return new Promise<JFUSMetrics>(async (resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.FILE_UPLOAD)}/rest/v1/organizations/${organizationId}/metrics`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Server error while getting metrics", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while getting metrics", ex)
        reject(ex)
      }
    })
  },
  getDASMetrics(organizationId: string): Promise<JDASMetrics> {
    return new Promise<JDASMetrics>(async (resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.DAS)}/rest/v1/organizations/${organizationId}/metrics`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Server error while getting metrics", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while getting metrics", ex)
        reject(ex)
      }
    })
  },
  getMISMetrics(organizationId: string): Promise<JMISMetrics> {
    return new Promise<JMISMetrics>(async (resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.MAP_IMAGE)}/rest/v1/organizations/${organizationId}/metrics`
          })
          .then(response => resolve(response.result))
          .catch((error: any) => {
            console.error("Server error while getting metrics", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while getting metrics", ex)
        reject(ex)
      }
    })
  }
}

function getApiKeysFromRaw(rawApiKeys: any[]): JApiKey[] {
  return rawApiKeys.map(rawApiKey => getApiKeyFromRaw(rawApiKey))
}

function getApiKeyFromRaw(rawApiKey: any): JApiKey {
  return {
    id: rawApiKey.id,
    title: rawApiKey.title,
    createdBy: rawApiKey.createdBy,
    email: rawApiKey.email,
    creationDate: new Date(rawApiKey.creationDate),
    expiresAt: rawApiKey.expiresAt ? new Date(rawApiKey.expiresAt) : null,
    roles: rawApiKey.roles
  }
}

function getOrganizationFromRaw(rawOrganization: any): JOrganization {
  return {
    ...rawOrganization,
    creationDate: new Date(rawOrganization.creationDate),
    lastModificationDate: new Date(rawOrganization.lastModificationDate)
  }
}
