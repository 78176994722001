import { Box, Theme } from "@mui/material"
import { JRootState } from "app/model"
import { JAppOverlayMessage } from "message/model"
import React from "react"
import { connect } from "react-redux"
import { LinearLoader } from "ui/components/LinearLoader"

export interface JOverlayMessagesProps {
  messages: JAppOverlayMessage[]
}

const OverlayMessagesFn = (props: JOverlayMessagesProps) => {
  if (props.messages.length === 0) {
    return null
  }
  const lastMessage: JAppOverlayMessage = props.messages[props.messages.length - 1]
  return (
    <Box
      sx={{
        "position": "absolute",
        "z-index": 10,
        "width": "100%",
        "height": "100%",
        "display": "flex",
        "alignItems": "center",
        "top": 0,
        "left": 0,
        "justifyContent": "center",
        "backgroundColor": (theme: Theme) => `${theme.palette.background.default}80`,
        "& > *": {
          width: "70%",
          textAlign: "center"
        },
        "& .linear-loader": {
          "backgroundColor": (theme: Theme) => theme.palette.background.paper,
          "padding": "2rem",
          "border-radius": "0.25rem",
          "max-width": "40rem"
        }
      }}
    >
      <LinearLoader className="linear-loader" text={lastMessage.text} />
    </Box>
  )
}

export const OverlayMessages = connect(
  (state: JRootState) =>
    ({
      messages: state.message.overlayMessages
    }) as JOverlayMessagesProps
)(OverlayMessagesFn)
