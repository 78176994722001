import { ACTIONS } from "app/store/actions"
import { Action, Reducer } from "redux"
import { JServerState } from "../model"
import { JServerActionSetServerInfo, JServerActionSetServerInfoIsLoading, JServerActionSetServerInfoLoadingError } from "./actions"
import { getDefaultServerState } from "./state"

export const serverReducer: Reducer<JServerState, Action> = (state: JServerState | undefined, action: Action): JServerState => {
  if (!state) {
    state = getDefaultServerState()
  }
  switch (action.type) {
    case ACTIONS.SERVER_SET_SERVER_INFO: {
      const { serverInfo } = action as JServerActionSetServerInfo
      return {
        ...state,
        isReady: true,
        version: serverInfo.version,
        microServiceById: serverInfo.microServiceById,
        identityProviders: serverInfo.identityProviders
      }
    }

    case ACTIONS.SERVER_SET_SERVER_INFO_IS_LOADING: {
      const { isLoading } = action as JServerActionSetServerInfoIsLoading
      return { ...state, isLoading }
    }

    case ACTIONS.SERVER_SET_SERVER_INFO_LOADING_ERROR: {
      const { hasLoadingError } = action as JServerActionSetServerInfoLoadingError
      return { ...state, hasLoadingError }
    }

    default:
      return state
  }
}
