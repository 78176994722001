import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Stack } from "@mui/material"
import React from "react"
import { copyFieldValueInClipboard } from "ui/utils"

export interface RichTextLabelAdornmentWithCopyButtonProps {
  value: string | number
  name: string
}

export const RichTextLabelAdornmentWithCopyButton = (props: RichTextLabelAdornmentWithCopyButtonProps) => (
  <Stack maxWidth={"100%"} direction={"row"} alignItems={"center"}>
    <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{props.value}</div>
    <IconButton
      size="small"
      onClick={() => {
        copyFieldValueInClipboard(props.value, props.name)
      }}
    >
      <FontAwesomeIcon icon={light("copy")} />
    </IconButton>
  </Stack>
)
