import { useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import Studio from "jmapcloud-studio"
import { organizationRPO } from "organization/repository"
import React from "react"
import { getRestBaseUrl } from "server/tools/common"
import { DataSourceDetailsDialog } from "spatialdatasource/components/DataSourceDetailsDialog"
import { setSdsToDisplayDetails } from "spatialdatasource/store"
import { selectUserOrganization, setUserOrganizations } from "user/store"
import { ProjectSelection } from "./ProjectSelection"

export const StudioPage = (): JSX.Element => {
  const theme = useTheme()
  const dataSourceToDisplay = useAppSelector(state => state.sds.sdsToDisplayDetails)
  const { authenticationToken, baseUrl, organizationId, projectId, defaultLanguage } = useAppSelector(state => ({
    authenticationToken: state.user.accessToken,
    baseUrl: getRestBaseUrl(),
    organizationId: state.user.selectedOrganization?.id ?? "",
    projectId: state.studio.projectId,
    defaultLanguage: state.studio.defaultLanguage
  }))
  const [mapboxApiKey, setMapboxApiKey] = React.useState<string>()
  React.useEffect(() => {
    const getapiKey = async () => {
      let apiKeys = store.getState().user.selectedOrganization!.externalApiKeys
      if (!apiKeys) {
        const organizations = store.getState().user.organizations
        const newOrganization = await organizationRPO.getOrganizationById(organizationId)
        const newOrganizations = organizations.map(org => (org.id === newOrganization.id ? newOrganization : org))
        store.dispatch(setUserOrganizations(newOrganizations))
        store.dispatch(selectUserOrganization(newOrganization))
        apiKeys = newOrganization.externalApiKeys
      }
      const mapboxKey = apiKeys!.find((key: any) => key.type === "MAPBOX_ACCESS_TOKEN")
      setMapboxApiKey(mapboxKey.apiKey)
    }
    getapiKey()
  }, [])

  return (
    <>
      {projectId === null && <ProjectSelection />}
      {mapboxApiKey && projectId && (
        <Stack height="100%" padding={0}>
          <Studio
            authenticationType={"token"}
            authenticationToken={authenticationToken}
            theme={theme}
            baseUrl={baseUrl}
            organizationId={organizationId}
            projectId={projectId ?? ""}
            mapboxToken={mapboxApiKey}
            defaultLanguage={defaultLanguage}
          />
        </Stack>
      )}

      {dataSourceToDisplay && (
        <DataSourceDetailsDialog
          dataSource={dataSourceToDisplay}
          onClose={() => {
            store.dispatch(setSdsToDisplayDetails(null))
          }}
        />
      )}
    </>
  )
}
