import { getMembersAndRoles } from "member/tools/common"
import { getOrganizationApiKeys } from "organization/tools/apikey"
import { getProjectAcls, getProjectMetrics } from "project/utils"
import * as React from "react"
import { MEMBER_ROLES } from "user/model"
import { JAPIKeyAcl, JAcl, JMemberAcl } from "./model"

type IntervalFunction = () => unknown

// This idea comes from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

export function useInterval(callback: IntervalFunction, delay: number | null) {
  const savedCallback = React.useRef<IntervalFunction | null>(null)

  React.useEffect(() => {
    if (delay !== null) {
      savedCallback.current = callback
    }
  })

  React.useEffect(() => {
    if (delay === null) {
      return
    }
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export function useProjectMetrics(projectId: string): [number | null, string | null, number[] | null, boolean] {
  const [volume, setVolume] = React.useState<number | null>(null)
  const [tilesCount, setTilesCount] = React.useState<string | null>(null)
  const [tilesCountPerLevel, setTilesCountPerLevel] = React.useState<number[] | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    setIsLoading(true)
    getProjectMetrics(projectId)
      .then(metrics => {
        let totalVolume = 0
        let totalTiles = 0
        Object.keys(metrics).forEach((key: any) => {
          totalVolume += metrics[key].totalDataSize
          totalTiles += metrics[key].numberOfTiles
        })
        setVolume(Number(totalVolume.toFixed(2)))
        setTilesCount(totalTiles.toLocaleString())
        setTilesCountPerLevel(metrics)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }, [])
  return [volume, tilesCount, tilesCountPerLevel, isLoading]
}

function getOrgRoleAvatar(role: MEMBER_ROLES): string {
  return role[4]
}

export function useAcls(projectId: string): [JAcl[], React.Dispatch<React.SetStateAction<JAcl[]>>] {
  const [acls, setAcls] = React.useState<JAcl[]>([])

  React.useEffect(() => {
    const getMembersAndApiKeys = async () => {
      const membersAndRoles = await getMembersAndRoles()
      const memberAcls: JMemberAcl[] = membersAndRoles.map(m => ({
        ...m,
        roles: m.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: m.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: false
      }))
      const fetchedApiKeys = await getOrganizationApiKeys()
      const apiKeyAcls: JAPIKeyAcl[] = fetchedApiKeys.map(k => ({
        ...k,
        name: k.title,
        roles: k.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: k.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: true
      }))
      const membersAndApiKeysAcls: JAcl[] = [...memberAcls, ...apiKeyAcls]
      const projectAcls = await getProjectAcls(projectId)
      const projectAclsByPrincipal = Object.fromEntries(projectAcls.map(a => [a.principal, a]))
      const memberAndProjectAcls = membersAndApiKeysAcls.map(a => {
        const projectAcl = projectAclsByPrincipal[a.email]
        return projectAcl ? { ...a, ...projectAcl, isAcl: true } : a
      })
      memberAndProjectAcls.sort((a1, a2) => a1.name.localeCompare(a2.name))
      setAcls(memberAndProjectAcls)
    }
    getMembersAndApiKeys()
  }, [])
  return [acls, setAcls]
}
