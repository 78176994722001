import { EventModule } from "app/event/EventModule"
import { APP_EVENTS, JAppEventModule } from "./model"

class AppEventModule extends EventModule implements JAppEventModule {
  public on = {
    storeReady: (listenerId: string, fn: () => void): void => {
      this.addListenerFn(APP_EVENTS.STORE_READY, listenerId, fn)
    },
    appReady: (listenerId: string, fn: () => void): void => {
      this.addListenerFn(APP_EVENTS.APP_READY, listenerId, fn)
    }
  }
}

export const appEventModule: AppEventModule = new AppEventModule()
