import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { store } from "app/store/store"
import React from "react"
import { getRestBaseUrl } from "server/tools/common"
import * as tus from "tus-js-client"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { JDataGridPagedResponse } from "ui/tools/grid"
import { setCallbackAtTokenRefresh } from "user/store"
import { getAccessToken, getUserOrganization } from "user/tools/common"
import { FILE_INFO_STATUSES, FILE_INFO_TYPES, JFileInfo, VIRTUAL_FILE_INFO_STATUSES } from "./model"
import { fileRPO } from "./repository"

export function getFileInfos(page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JFileInfo>> {
  return fileRPO.get(getUserOrganization().id, page, size, sortModel, filterModel)
}

export function getFileInfo(fileId: string): Promise<JFileInfo> {
  return fileRPO.getOne(getUserOrganization().id, fileId)
}

// Returns entire list of analyzed files by fetching all pages
export async function getAllUsableFileInfos(): Promise<JFileInfo[]> {
  let fileInfos: JFileInfo[] = []
  let resp: JDataGridPagedResponse<JFileInfo>
  let page = 0
  const organizationId = getUserOrganization().id
  do {
    resp = await fileRPO.get(organizationId, page, 50, [{ field: "creationDate", sort: "desc" }], {
      items: [{ columnField: "status", operatorValue: "is", value: FILE_INFO_STATUSES.ANALYZED }]
    })
    fileInfos = fileInfos.concat(resp.result)
    page += 1
  } while (fileInfos.length < resp.page.totalElements)
  // also filter out the invalid rasters (analysed but no CRS)
  return fileInfos.filter(f => !(f.type === FILE_INFO_TYPES.RASTER_DATA && f.metadata.crs === null))
}

export function deleteFile(fileId: string): Promise<void> {
  return fileRPO.delete(getUserOrganization().id, fileId)
}

export const ACCEPTED_UPLOAD_MIME_TYPES = {
  VECTORS: {
    "application/zip": [],
    "application/x-zip-compressed": []
  },
  RASTERS: {
    "image/tiff": [],
    "application/x-geotiff": []
  }
}

export const ALL_ACCEPTED_UPLOAD_MIME_TYPES = {
  ...ACCEPTED_UPLOAD_MIME_TYPES.VECTORS,
  ...ACCEPTED_UPLOAD_MIME_TYPES.RASTERS
}

export function uploadFile(file: File, options: tus.UploadOptions) {
  const upload = new tus.Upload(file, {
    // Endpoint is the upload creation URL from your tus server
    endpoint: `${getRestBaseUrl()}/api/fus/rest/v1/organizations/${getUserOrganization().id}/upload`,
    // Retry delays will enable tus-js-client to automatically retry on errors
    retryDelays: [0, 3000, 5000, 10000, 20000],
    chunkSize: 5e7, // 50 MB
    headers: { Authorization: getAccessToken() },
    // Attach additional meta data about the file for the server
    metadata: {
      "filename": file.name,
      "filetype": file.type,
      "JMC-fileType": file.name.endsWith(".zip") ? FILE_INFO_TYPES.VECTOR_DATA : FILE_INFO_TYPES.RASTER_DATA
    },
    // Callback for errors which cannot be fixed using retries
    onError: error => {
      console.error("Upload error: ", error)
      store.dispatch(setCallbackAtTokenRefresh(null))
    },
    // Callback for reporting upload progress
    onProgress: (bytesUploaded, bytesTotal) => {
      const percentage = (bytesUploaded / bytesTotal) * 100
      console.info(bytesUploaded, bytesTotal, percentage + "%")
    },
    // Callback for once the upload is completed
    onSuccess: () => {
      console.info("Upload complete")
      store.dispatch(setCallbackAtTokenRefresh(null))
    },
    ...options
  })

  // Check if there are any previous uploads to continue.
  upload.findPreviousUploads().then(previousUploads => {
    // Found previous uploads so we select the first one.
    if (previousUploads.length > 0) {
      console.info("Resuming upload of: ", previousUploads[0])
      upload.resumeFromPreviousUpload(previousUploads[0])
    }

    store.dispatch(
      setCallbackAtTokenRefresh(() => {
        if (!upload.options.headers) {
          upload.options.headers = {}
        }
        upload.options.headers.Authorization = getAccessToken()
      })
    )
    upload.start()
  })

  return upload
}

export function getFileTypeLabel(fileInfo: JFileInfo): string {
  if (fileInfo.type === FILE_INFO_TYPES.VECTOR_DATA) {
    return fileInfo.metadata?.fileType ? translate(`sds.file.format.${fileInfo.metadata.fileType}`) : ""
  } else {
    return fileInfo.metadata?.fileType ?? ""
  }
}

function getStatusChipLevelFromFileStatus(fileStatus: FILE_INFO_STATUSES | VIRTUAL_FILE_INFO_STATUSES): STATUS_CHIP_LEVELS {
  switch (fileStatus) {
    case FILE_INFO_STATUSES.ANALYZED:
    case FILE_INFO_STATUSES.UPLOADED:
      return STATUS_CHIP_LEVELS.GREEN
    case FILE_INFO_STATUSES.ANALYZING:
    case FILE_INFO_STATUSES.UPLOADING:
    case FILE_INFO_STATUSES.FINISHING_UPLOAD:
    case FILE_INFO_STATUSES.WAITING:
    case VIRTUAL_FILE_INFO_STATUSES.PAUSED:
    case VIRTUAL_FILE_INFO_STATUSES.RESUMABLE:
      return STATUS_CHIP_LEVELS.ORANGE
    case FILE_INFO_STATUSES.ERROR:
      return STATUS_CHIP_LEVELS.RED
    default:
      return STATUS_CHIP_LEVELS.NEUTRAL
  }
}

export function getStatusChipFromFileStatus(fileStatus: FILE_INFO_STATUSES | VIRTUAL_FILE_INFO_STATUSES): JSX.Element {
  return <StatusChip label={translate(`file.status.${fileStatus}`)} level={getStatusChipLevelFromFileStatus(fileStatus)}></StatusChip>
}
