import { ACTIONS } from "app/store/actions"
import { Action, Reducer } from "redux"
import { JUiState } from "../model"
import { JActionSetTheme } from "./actions"
import { getDefaultUiState } from "./state"

export const uiReducer: Reducer<JUiState, Action> = (state: JUiState | undefined, action: Action): JUiState => {
  if (!state) {
    state = getDefaultUiState()
  }
  switch (action.type) {
    case ACTIONS.APP_UI_RESET_STATE: {
      return getDefaultUiState()
    }

    case ACTIONS.APP_UI_SET_THEME: {
      const { theme } = action as JActionSetTheme
      return { ...state, theme }
    }

    default:
      return state
  }
}
