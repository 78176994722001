import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as tus from "tus-js-client"
import { gridStateReducers } from "ui/tools/grid"
import { JFileGridState } from "./model"

const initialState: JFileGridState = {
  uploadPercentCompletedByFileId: {}, // fileId -> percent
  uploadByFileId: {}, // fileId -> tus.Upload
  uploadIsPausedByFileId: {}, // fileId -> boolean
  uploadQueue: [],

  page: 0,
  pageSize: 100,
  totalRowCount: 0,
  sortModel: [{ field: "filename", sort: "asc" }],
  filterModel: { items: [] },
  reloadCounter: 0
}

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    ...{
      setUpload(state: JFileGridState, action: PayloadAction<{ fileId: string; upload: tus.Upload }>) {
        const { fileId, upload } = action.payload
        return {
          ...state,
          uploadByFileId: {
            ...state.uploadByFileId,
            [fileId]: upload
          }
        }
      },
      deleteUpload(state: JFileGridState, action: PayloadAction<string>) {
        const fileId = action.payload
        const {
          uploadByFileId: { [fileId]: _1, ...uploadByFileId },
          uploadIsPausedByFileId: { [fileId]: _2, ...uploadIsPausedByFileId },
          uploadPercentCompletedByFileId: { [fileId]: _3, ...uploadPercentCompletedByFileId }
        } = state
        return {
          ...state,
          uploadByFileId,
          uploadIsPausedByFileId,
          uploadPercentCompletedByFileId
        }
      },
      setUploadIsPaused(state: JFileGridState, action: PayloadAction<{ fileId: string; isPaused: boolean }>) {
        const { fileId, isPaused } = action.payload
        return {
          ...state,
          uploadIsPausedByFileId: {
            ...state.uploadIsPausedByFileId,
            [fileId]: isPaused
          }
        }
      },
      setUploadPercentCompleted(state: JFileGridState, action: PayloadAction<{ fileId: string; percent: number }>) {
        const { fileId, percent } = action.payload
        return {
          ...state,
          uploadPercentCompletedByFileId: {
            ...state.uploadPercentCompletedByFileId,
            [fileId]: percent
          }
        }
      },
      pushToUploadQueue(state: JFileGridState, action: PayloadAction<File>) {
        const fileId = action.payload
        return {
          ...state,
          uploadQueue: [...state.uploadQueue, fileId]
        }
      },
      popFromUploadQueue(state: JFileGridState) {
        const [fileId, ...rest] = state.uploadQueue
        return {
          ...state,
          uploadQueue: rest
        }
      }
    },
    ...gridStateReducers
  }
})

export const {
  setUpload,
  deleteUpload,
  setUploadIsPaused,
  setUploadPercentCompleted,
  pushToUploadQueue,
  popFromUploadQueue,

  setPage,
  setPageSize,
  setTotalRowCount,
  setSortModel,
  setFilterModel,
  reload
} = fileSlice.actions

export default fileSlice.reducer
