import { appCFG } from "app/config"

const history = appCFG.history

export function transformSearchParamsIntoHashParams(paramNames: string | string[]): void {
  if (!paramNames || (Array.isArray(paramNames) && paramNames.length === 0)) {
    throw Error("you must specifiy the parameter(s) to transform")
  }
  if (!Array.isArray(paramNames)) {
    paramNames = [paramNames]
  }
  for (const paramName of paramNames) {
    if (typeof paramName !== "string") {
      throw Error("parameter names must be strings")
    }
    if (paramName === "") {
      throw Error("invalid empty parameter name")
    }
  }
  const hashParameters = { ...history.currentHashParameters }
  let search = ""
  if (window.location.search && window.location.search.length > 1) {
    search = window.location.search.substr(1) // remove "?"
  }
  const searchParameters = getParametersFromString(search)
  for (const key of paramNames) {
    if (searchParameters.hasOwnProperty(key)) {
      hashParameters[key] = searchParameters[key]
      delete searchParameters[key]
    }
  }
  let searchParams: string = getStringFromParameters(searchParameters)
  if (searchParams) {
    searchParams = `?${searchParams}`
  }
  const hashParams: string = getStringFromParameters(hashParameters)
  window.history.replaceState(searchParams, document.title, `${history.browserHistory.location.pathname}${searchParams}#${hashParams}`)
  history.currentHashParameters = hashParameters
}

export function popHashParameters(parameterName: string): void {
  checkParameterName(parameterName)
  delete history.currentHashParameters[parameterName]
  setHashParameters(history.currentHashParameters)
}

export function getHashParameters(): { [key: string]: string } {
  let hash: string = history.browserHistory.location.hash
  const parameters: { [key: string]: string } = {}
  if (!hash || hash.length < 2) {
    return parameters
  }
  // hash ex : "#test=false&test2=3"
  hash = hash.substr(1, hash.length) // remove "#" at the beginning
  // hash ex : "test=false&test2=3"
  return getParametersFromString(hash)
}

/**
 * Ex. if parameters = { myvar: "test", myothervar: "test2" }
 * Will return the string "myvar=test&myothervar=test2"
 */
export function getStringFromParameters(parameters: { [key: string]: string }): string {
  let params: string = ""
  if (parameters) {
    const keys: string[] = Object.keys(parameters)
    for (const key of keys) {
      params += params === "" ? "" : "&"
      params += `${key}=${encodeURI(parameters[key])}`
    }
  }
  return params
}

export function checkParameterName(parameterName: string): void {
  if (!parameterName || typeof parameterName !== "string" || parameterName === "") {
    throw Error("Bad parameter name")
  }
}

export function setHashParameters(parameters: { [key: string]: string }): void {
  history.currentHash = `#${getStringFromParameters(parameters)}`
  location.hash = history.currentHash
}

/**
 * Ex. if params = "myvar=test&myothervar=test2"
 * Will return the object { myvar: "test", myothervar: "test2" }
 */
export function getParametersFromString(params: string): { [key: string]: string } {
  const parameters: { [key: string]: string } = {}
  if (!params || params.length < 1) {
    return parameters
  }
  const entries: string[] = params.split("&")
  for (const entry of entries) {
    const split: string[] = entry.split("=")
    if (split.length < 2) {
      continue
    }
    parameters[decodeURI(split[0])] = decodeURI(split[1])
  }
  return parameters
}

export function removeTokenInURL(): void {
  transformSearchParamsIntoHashParams("token")
  popHashParameters("token")
}
