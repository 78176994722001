export function loadJSFile(fileUrl: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      if (!fileUrl || typeof fileUrl !== "string") {
        return reject("Invalid fileUrl parameter")
      }
      const scriptTag = document.createElement("script")
      scriptTag.onload = () => resolve()
      scriptTag.onerror = () => reject(`Cannot load the file "${fileUrl}" dynamically`)
      scriptTag.src = fileUrl
      document.head.appendChild(scriptTag)
    } catch (ex) {
      reject(ex)
    }
  })
}
