import { ACTIONS } from "app/store/actions"
import { LOCALES } from "jmapcloud-types"
import { Action } from "redux"

export interface JLanguageSetLocale extends Action {
  locale: LOCALES
}

export const languageActionCreator = {
  setLocale(locale: LOCALES): JLanguageSetLocale {
    return {
      type: ACTIONS.LANGUAGE_SET_LOCALE,
      locale
    }
  }
}
