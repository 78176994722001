import { store } from "app/store/store"
import { localStorageSVC } from "app/tool/local-storage/service"
import { getUserOrganization } from "user/tools/common"
import { EMPTY_TOKEN_VALUE, JUserService } from "./model"
import { getUsername, logout } from "./tools/common"
import { getQualifiedPreferenceName } from "./tools/preference"

export const userSVC: JUserService = {
  getOrganizationId: () => getUserOrganization().id,
  getUserName: getUsername,
  getPreference(name: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      try {
        resolve(localStorageSVC.get(getQualifiedPreferenceName(name)))
      } catch (error) {
        console.error("Unable to access preferences.", error)
        return reject("Unable to access preferences.")
      }
    })
  },
  hasPreference(name: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        resolve(localStorageSVC.get(getQualifiedPreferenceName(name)) !== null)
      } catch (error) {
        console.error("Unable to access preferences.", error)
        return reject("Unable to access preferences.")
      }
    })
  },
  removePreference(name: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      try {
        const qualifiedPreferenceName = getQualifiedPreferenceName(name) // throw if invalid
        const value = localStorageSVC.get(qualifiedPreferenceName)
        localStorageSVC.remove(qualifiedPreferenceName)
        resolve(value)
      } catch (error) {
        console.error("Unable to access preferences.", error)
        return reject("Unable to access preferences.")
      }
    })
  },
  setPreference(name: string, value: string | undefined): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const qualifiedPreferenceName = getQualifiedPreferenceName(name) // throw if invalid
        if (value === undefined) {
          localStorageSVC.remove(qualifiedPreferenceName)
          return resolve()
        } else if (typeof value === "string") {
          localStorageSVC.set(qualifiedPreferenceName, value)
          return resolve()
        }
        const errorMessage: string = `Cannot set preference "${qualifiedPreferenceName}", value must be a string or undefined`
        console.error(errorMessage)
        reject(errorMessage)
      } catch (error) {
        console.error("Unable to access preferences.", error)
        return reject("Unable to access preferences.")
      }
    })
  },
  logout,
  isLoggedIn(): boolean {
    return store.getState().user.accessToken !== EMPTY_TOKEN_VALUE
  },
  getToken(): string {
    return store.getState().user.accessToken
  }
}
