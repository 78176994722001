import { LinearProgress } from "@mui/material"
import { DataGridPro, DataGridProProps, enUS, esES, frFR } from "@mui/x-data-grid-pro"
import { languageSVC } from "app/language/service"
import React from "react"

export const PortalDataGrid = (props: DataGridProProps & { rowType: string }) => {
  const { componentsProps, ...remainingProps } = props

  const getLocaleText = () => {
    const locale = languageSVC.getLocale()
    if (locale === "es") {
      return esES.components.MuiDataGrid.defaultProps.localeText
    } else if (locale === "fr") {
      return frFR.components.MuiDataGrid.defaultProps.localeText
    } else {
      return enUS.components.MuiDataGrid.defaultProps.localeText
    }
  }
  return (
    <DataGridPro
      sx={{
        "width": "100%",
        "height": "100%",
        "& *": {
          userSelect: "none"
        }
      }}
      pagination
      rowsPerPageOptions={[50, 100, 200]}
      disableSelectionOnClick
      disableColumnSelector
      localeText={getLocaleText()}
      hideFooterSelectedRowCount
      sortingOrder={["asc", "desc"]}
      components={{
        LoadingOverlay: LinearProgress
      }}
      componentsProps={{
        pagination: {
          showFirstButton: true,
          showLastButton: true
        },
        ...componentsProps
      }}
      {...remainingProps}
    ></DataGridPro>
  )
}
