import { EventModule } from "app/event/EventModule"
import { JJobEventCancelParams, JJobEventModule, JOB_EVENTS } from "./model"

class JobEventModule extends EventModule implements JJobEventModule {
  public on = {
    cancel: (listenerId: string, fn: (params: JJobEventCancelParams) => void): void => {
      this.addListenerFn(JOB_EVENTS.CANCEL, listenerId, fn)
    }
  }
}

export const jobEventModule: JobEventModule = new JobEventModule()
