import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, FormControl, FormLabel, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import React from "react"
import { JAttribute } from "spatialdatasource/model"

interface JInlineEditableAttributesTableProps {
  attributes: JAttribute[]
  isEditable: boolean
  disabled: boolean
  setIsEditable: (value: boolean) => void
  onAcceptEdit: (value: any) => void
}

export const InlineEditableAttributesTable = (props: JInlineEditableAttributesTableProps) => {
  const [attributes, setAttributes] = React.useState([...props.attributes])
  const [isHovered, setIsHovered] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    setAttributes(props.attributes)
  }, [props.isEditable])

  const acceptEdit = () => {
    props.onAcceptEdit(attributes)
    props.setIsEditable(false)
  }

  const rejectEdit = () => {
    props.setIsEditable(false)
    setAttributes(props.attributes)
  }

  const handleCheckboxChange = (attributeName: string) => {
    const newAttributes = attributes.map(attr => {
      if (attr.name === attributeName) {
        return { ...attr, indexed: !attr.indexed }
      }
      return attr
    })
    setAttributes(newAttributes)
  }

  return (
    <FormControl fullWidth onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <FormLabel sx={{ fontSize: "0.75rem" }}>{translate("label.attributes")}</FormLabel>
      <TableContainer sx={{ maxHeight: "250px" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{translate("label.name")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{translate("label.type")}</TableCell>
              <TableCell sx={{ fontWeight: "bold", minWidth: "115px" }}>
                <Stack sx={{ position: "relative" }} direction="row" alignItems="center" gap={0.5}>
                  {translate("label.indexed")}
                  <div style={{ position: "absolute", left: "50px", top: 0, height: "100%", display: "flex", alignItems: "center" }}>
                    {isHovered && !props.disabled && !props.isEditable && (
                      <IconButton sx={{ color: theme.palette.text.secondary, marginLeft: "5px" }} size="small" onClick={() => props.setIsEditable(true)}>
                        <FontAwesomeIcon size="xs" icon={light("pencil")} />
                      </IconButton>
                    )}
                    {props.isEditable && (
                      <>
                        <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onMouseDown={acceptEdit}>
                          <FontAwesomeIcon icon={light("check")} />
                        </IconButton>
                        <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onClick={rejectEdit}>
                          <FontAwesomeIcon icon={light("x")} />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attributes.map((attribute, index) => (
              <TableRow key={index}>
                <TableCell>{attribute.name}</TableCell>
                <TableCell>{attribute.type}</TableCell>
                <TableCell>
                  <Checkbox checked={attribute.indexed} {...(!props.isEditable && { disabled: true })} onChange={() => handleCheckboxChange(attribute.name)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  )
}
