import { JEventModule } from "app/event/model"
import { PrimitiveType } from "intl-messageformat"
import { LOCALES } from "jmapcloud-types"

export const ALL_LOCALES: LOCALES[] = ["en", "fr", "es"]

export enum JLANGUAGE_EVENTS {
  INITIALIZED = "INITIALIZED",
  LOCALE_CHANGE = "LOCALE_CHANGE"
}

export const DEFAULT_LOCALE: LOCALES = "en"
export const LAST_LOCALE_LOCAL_STORAGE_ID = "lastUsedLocale"
export const USER_CHANGE_LOCALE_LOCAL_STORAGE_ID = "userChangedLocale"
export const PARAM_OVERRIDEN_LOCALE = "ngLocale"

export type JCoreTranslateFn = (key: string, values?: JTranslateParamValues, paramLocale?: LOCALES) => string

export interface JNavigator extends NavigatorLanguage {
  readonly userLanguage?: string
}

export interface JLanguageState {
  locale: LOCALES
}

export interface JLanguageEventModule extends JEventModule {
  on: {
    initialized(listenerId: string, fn: () => void): void
    localeChange(listenerId: string, fn: (params: JLanguageEventLocaleChangeParams) => void): void
  }
}

export interface JLanguageEventLocaleChangeParams {
  locale: LOCALES
}

export interface JLanguageService {
  getLocales(): LOCALES[]
  getLocale(): LOCALES
  getDateFnsLocale(displayTime?: boolean): any
  getDefaultLocale(): LOCALES
  setLocale(locale: LOCALES): void
  translate(params: JTranslateParams): string
  is12HoursTimeFormat(): boolean
  isValidLocale(locale: LOCALES): boolean
  getDateFnsDateFormat(): string
}

export interface JTranslateParams {
  key: string
  values?: JTranslateParamValues
  locale?: LOCALES
}

export interface JTranslateParamValues {
  [key: string]: PrimitiveType
}

export type JTranslationsByLocale = {
  [locale in LOCALES]?: { [key: string]: string }
}

export type JTranslationByLocale = { [locale in LOCALES]: string }
