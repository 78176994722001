import { ajaxSVC } from "app/ajax/service"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JMember, JMemberRepository, JMemberUpdateParams } from "./model"

const memberRPO: JMemberRepository = {
  getAllMembers(organizationId: string): Promise<JMember[]> {
    return new Promise<JMember[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/members`
          })
          .then((response: any) => resolve(getMembersFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting all members from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting all members", ex)
        reject(ex)
      }
    })
  },
  getById(memberId: string, organizationId: string): Promise<JMember> {
    return new Promise<JMember>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/members/${encodeURIComponent(memberId)}`
          })
          .then((response: any) => resolve(getMemberFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting member from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting member", ex)
        reject(ex)
      }
    })
  },
  update(memberId: string, organizationId: string, params: JMemberUpdateParams): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .patch({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/members/${encodeURIComponent(memberId)}`,
            params: {
              roles: params.roles
            }
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error("Error while updating member", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while updating member", ex)
        reject(ex)
      }
    })
  },
  delete(memberId: string, organizationId: string, transferredOwnerId?: string): Promise<void> {
    const newOwnerParam = transferredOwnerId ? `?transferOwnershipTo=${encodeURIComponent(transferredOwnerId)}` : ""
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/members/${encodeURIComponent(memberId)}${newOwnerParam}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error("Error while deleting member", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while deleting member", ex)
        reject(ex)
      }
    })
  }
}

function getMembersFromRaw(rawMembers: any[]): JMember[] {
  return rawMembers.map(rawMember => getMemberFromRaw(rawMember))
}

function getMemberFromRaw(rawMember: any): JMember {
  return {
    id: rawMember.email,
    name: rawMember.name,
    email: rawMember.email,
    organizationId: rawMember.organizationId,
    roles: Array.isArray(rawMember.roles) ? rawMember.roles : []
  }
}

export default memberRPO
