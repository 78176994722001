import { JServerExtent } from "jmapcloud-types"
import { getRestBaseUrl } from "server/tools/common"
import { ABSOLUTE_URL_REGEX, SAAS_GEOJSON_URL_REGEX, SAAS_MAPBOX_URL_REGEX, SAAS_MVT_URL_REGEX, SAAS_SDS_MVT_URL_REGEX, SAAS_WMS_URL_REGEX } from "./model"

export function isJMCAuthenticatedRequest(url: string): boolean {
  const restBaseUrl = getRestBaseUrl()
  // TODO: make this better to take into account round-robin on restBaseUrl in MapBox services
  return (
    (restBaseUrl.match(ABSOLUTE_URL_REGEX) && url.startsWith(restBaseUrl)) ||
    url.match(SAAS_MVT_URL_REGEX) !== null ||
    url.match(SAAS_MAPBOX_URL_REGEX) !== null ||
    url.match(SAAS_GEOJSON_URL_REGEX) !== null ||
    url.match(SAAS_SDS_MVT_URL_REGEX) !== null ||
    url.match(SAAS_WMS_URL_REGEX) !== null
  )
}

export function getExtentFromMapWindow(map: maplibregl.Map, windowCoords: { top: number; bottom: number; right: number; left: number }): JServerExtent | null {
  const mapContainer = document.getElementById("sds-map")?.getBoundingClientRect()
  if (!mapContainer) {
    return null
  }
  const windowTopLeftPoint = map.unproject({ x: windowCoords.left - mapContainer?.left, y: windowCoords.top - mapContainer?.top } as maplibregl.PointLike)
  const windowBottomRightPoint = map.unproject({ x: windowCoords.right - mapContainer?.left, y: windowCoords.bottom - mapContainer?.top } as maplibregl.PointLike)

  return {
    x1: windowTopLeftPoint.lng,
    x2: windowBottomRightPoint.lng,
    y1: windowBottomRightPoint.lat,
    y2: windowTopLeftPoint.lat
  }
}

export const getSourcedLayerId = (organizationId: string, dataSourceId: string): string => {
  const splitOrganizationId = organizationId.split("-")
  const splitDataSourceId = dataSourceId.split("-")

  return `sds_${splitOrganizationId[0]}_${splitOrganizationId[splitOrganizationId.length - 1]}_${splitDataSourceId[0]}_${splitDataSourceId[splitDataSourceId.length - 1]}`
}
