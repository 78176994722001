import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, Box, IconButton, TextField, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { StatusChip } from "./StatusChip"

interface JInlineEditableAutocompleteProps {
  DisplayComponent: any
  DisplayComponentChild?: any
  DisplayComponentProps?: any
  disabled?: boolean
  options: string[]
  label: string
  AutocompleteProps?: any
  initialValue: string | string[]
  onAcceptEdit: (value: any) => void
}

export const InlineEditableAutocomplete = (props: JInlineEditableAutocompleteProps) => {
  const [value, setValue] = React.useState(props.initialValue)
  const [isEditable, setIsEditable] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const theme = useTheme()

  const inputRef = React.useRef<HTMLInputElement>()

  React.useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus()
    }
  }, [isEditable])

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.value === "") {
      e.preventDefault()
      acceptEdit()
    } else if (e.key === "Escape") {
      e.preventDefault()
      rejectEdit()
    }
  }

  const acceptEdit = () => {
    props.onAcceptEdit(value)
    setIsEditable(false)
    setIsHovered(false)
  }

  const rejectEdit = () => {
    setValue(props.initialValue)
    setIsEditable(false)
    setIsHovered(false)
  }

  const handleChange = (e: any, v: any) => {
    setValue(v)
  }

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Stack direction="row" alignItems="center">
        {isEditable && !props.disabled ? (
          <Autocomplete
            {...props.AutocompleteProps}
            disablePortal
            disableClearable
            fullWidth
            disableListWrap
            multiple={Array.isArray(props.initialValue)}
            freeSolo={Array.isArray(props.initialValue)}
            value={value}
            options={props.options}
            onChange={handleChange}
            onBlur={rejectEdit}
            renderInput={params => <TextField inputRef={inputRef} onKeyDown={handleKeyDown} {...params} label={props.label} />}
            renderTags={(val: readonly string[], getTagProps) =>
              val.map((option: string, index: number) => <StatusChip label={option} level={STATUS_CHIP_LEVELS.NEUTRAL} {...getTagProps({ index })} />)
            }
          />
        ) : (
          <props.DisplayComponent {...props.DisplayComponentProps} onClick={() => setIsEditable(true)}>
            {props.DisplayComponentChild}
          </props.DisplayComponent>
        )}
        {isHovered && !isEditable && !props.disabled && (
          <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onClick={() => setIsEditable(true)}>
            <FontAwesomeIcon size="xs" icon={light("pencil")} />
          </IconButton>
        )}
        {isEditable && !props.disabled && (
          <>
            {/* onMouseDown instead of onClick is needed here because if not, the onBlur of the AC is called first */}
            <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onMouseDown={acceptEdit}>
              <FontAwesomeIcon icon={light("check")} />
            </IconButton>
            <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onClick={rejectEdit}>
              <FontAwesomeIcon icon={light("x")} />
            </IconButton>
          </>
        )}
      </Stack>
    </Box>
  )
}
