import { Button, Card, CardContent, CircularProgress, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { languageSVC, translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { filesize } from "filesize"
import { useProjectMetrics } from "project/hooks"
import { setProjectMvtCacheDialog } from "project/store"
import React from "react"

interface ProjectMvtTileCacheDialogStatusProps {
  projectId: string
}
export const ProjectMvtTileCacheDialogStatus = ({ projectId }: ProjectMvtTileCacheDialogStatusProps) => {
  const dispatch = useAppDispatch()
  const [volume, tilesCount, tilesCountPerLevel, isLoading] = useProjectMetrics(projectId)

  return (
    <>
      <DialogContent sx={{ padding: "30px 0", borderBottom: 1, borderColor: "divider", overflow: "visible" }}>
        <Grid container gap={5} display="flex" direction="row" alignItems="start">
          <CardDisplay data={`${filesize(volume ? volume : 0, { locale: languageSVC.getLocale() })}`} label={translate("mvt.cache.dialog.state.volume")} isLoading={isLoading} />
          <CardDisplay data={`${tilesCount}`} label={translate("mvt.cache.dialog.state.tiles.cached", { numTiles: tilesCount })} isLoading={isLoading} />
          <TilesCountPerLevelTable tilesCountPerLevel={tilesCountPerLevel} isLoading={isLoading} />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", marginTop: "20px" }}>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(setProjectMvtCacheDialog(null))
          }}
        >
          {translate("button.cancel")}
        </Button>
      </DialogActions>
    </>
  )
}

interface CardDisplayProps {
  data: string
  label: string
  isLoading: boolean
}
const CardDisplay = ({ data, label, isLoading }: CardDisplayProps) => {
  const theme = useTheme()
  return (
    <Card sx={{ aspectRatio: "4/3", flex: "1" }}>
      <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
        {isLoading ? (
          <CircularProgress size={40} />
        ) : (
          <Typography variant="h4" sx={{ color: `${theme.palette.primary.dark}`, fontWeight: "bold" }}>
            {data}
          </Typography>
        )}
        <Typography variant="h6">{label}</Typography>
      </CardContent>
    </Card>
  )
}

interface TilesCountPerLevelTableProps {
  isLoading: boolean
  tilesCountPerLevel: any
}
const TilesCountPerLevelTable = ({ tilesCountPerLevel, isLoading }: TilesCountPerLevelTableProps) => {
  const theme = useTheme()
  return (
    <Grid item sx={{ flex: "2" }}>
      <TableContainer sx={{ maxHeight: "300px" }}>
        <Table stickyHeader sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: `${theme.palette.text.secondary}` }}>
            <TableRow>
              <TableCell sx={{ padding: "5px 16px " }}>{translate("mvt.cache.dialog.state.levels")}</TableCell>
              <TableCell sx={{ padding: "5px 16px " }} align="right">
                {translate("mvt.cache.dialog.state.tiles.number")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: "center", height: "265px" }}>
                  <CircularProgress size={50} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {tilesCountPerLevel &&
                  Object.keys(tilesCountPerLevel).map(level => (
                    <TableRow key={level} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "padding": "0" }}>
                      <TableCell sx={{ border: 0, padding: "1px 16px " }} component="th" scope="row">
                        {translate("mvt.cache.dialog.state.level")} {level}
                      </TableCell>
                      <TableCell sx={{ border: 0, padding: "1px 16px " }} align="right">
                        {tilesCountPerLevel[level].numberOfTiles} {translate("mvt.cache.dialog.state.tile", { numTiles: tilesCountPerLevel[level].numberOfTiles })}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
