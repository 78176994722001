import { CircularProgress, Tooltip } from "@mui/material"
import { translate } from "app/language/service"
import { deleteOrganizationApikey } from "organization/tools/apikey"
import { messageSVC } from "message/service"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"

interface JOrganizationApiKeyListMenuProps {
  apiKeyId: string
  onShowEditDialog: () => void
  onClose: () => void
  onDelete: () => void
}

export const OrganizationApiKeyGridRowMenu = (props: JOrganizationApiKeyListMenuProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const isDisabled: boolean = isDeleting
  const items: JButtonMenuItem[] = [
    {
      title: translate("organization.apikey.details.tooltip"),
      disabled: isDisabled,
      onClick: () => {
        if (isDisabled) {
          return
        }
        props.onShowEditDialog()
      }
    },
    {
      title: translate("organization.apikey.delete.title"),
      disabled: isDeleting,
      onClick: () => {
        if (isDisabled) {
          return
        }
        setIsDeleting(true)
        deleteOrganizationApikey(props.apiKeyId)
          .then(isDeleted => {
            setIsDeleting(false)
            props.onDelete()
          })
          .catch(error => {
            console.error(error)
            messageSVC.error(translate("organization.apikey.delete.error"))
            setIsDeleting(false)
          })
      }
    }
  ]
  return isDeleting ? (
    <Tooltip title={translate("organization.apikey.deleting.title")}>
      <CircularProgress size={24} />
    </Tooltip>
  ) : (
    <ButtonMenu id={`organization-apikey-menu-${props.apiKeyId}`} small items={items} onClose={props.onClose} />
  )
}
