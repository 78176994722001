import { Chip, ChipProps, useTheme } from "@mui/material"
import React from "react"
import { STATUS_CHIP_LEVELS } from "ui/model"

export interface StatusChipProps extends ChipProps {
  level: STATUS_CHIP_LEVELS
  label: string
}

export const StatusChip = (props: StatusChipProps): JSX.Element => {
  const theme = useTheme()
  const darkColor =
    props.level === STATUS_CHIP_LEVELS.NEUTRAL
      ? theme.palette.info.dark
      : props.level === STATUS_CHIP_LEVELS.GREEN
      ? theme.palette.success.main
      : props.level === STATUS_CHIP_LEVELS.ORANGE
      ? theme.palette.warning.main
      : props.level === STATUS_CHIP_LEVELS.RED
      ? theme.palette.error.main
      : theme.palette.text.primary
  const lightColor =
    props.level === STATUS_CHIP_LEVELS.NEUTRAL
      ? theme.palette.info.light
      : props.level === STATUS_CHIP_LEVELS.GREEN
      ? theme.palette.success.light
      : props.level === STATUS_CHIP_LEVELS.ORANGE
      ? theme.palette.warning.light
      : props.level === STATUS_CHIP_LEVELS.RED
      ? theme.palette.error.light
      : theme.palette.text.disabled
  const color = theme.palette.mode === "dark" ? lightColor : darkColor
  const backgroundColor = theme.palette.mode === "dark" ? darkColor : lightColor
  return (
    <Chip
      {...props}
      label={props.label}
      size="small"
      variant="outlined"
      sx={{
        ...props.sx,
        color,
        backgroundColor
      }}
    />
  )
}
