import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import { JMember } from "member/model"
import memberRPO from "member/repository"
import { updateMember } from "member/tools/common"
import React from "react"
import { TextLabel } from "ui/components/TextLabel"
import { RoleSelect } from "user/components/RoleSelect"
import { ALL_MEMBER_ROLES, MEMBER_ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"
import { useErrorHandling } from "app/hook"

interface JMemberUpdateProps {
  member: JMember
  onClose(): any
  onUpdate(): any
}

export const MemberUpdateDialog = (props: JMemberUpdateProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("member.update.submit.error"))
  const { hasError: hasLoadingError, errorMessage: LoadingErrorMessage, handleError: handleLoadingError } = useErrorHandling(translate("member.update.loading.error"))
  const [isLoading, setIsLoading] = React.useState(false)
  const [roles, setRoles] = React.useState(Array<MEMBER_ROLES>)

  React.useEffect(() => {
    // The member must be loaded to get its role
    setIsLoading(true)
    memberRPO
      .getById(props.member.id, getUserOrganization().id)
      .then(async fetchedMember => {
        setRoles(fetchedMember.roles)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        handleLoadingError(error)
      })
  }, [])

  const initialValues = { roles }

  const submit = async (values: any, setSubmitting: any) => {
    if (hasError) {
      resetError()
    }
    if (!deepEquals(values, initialValues)) {
      try {
        await updateMember(props.member.id, getUserOrganization().id, values)
        props.onClose()
      } catch (error: any) {
        console.error(error)
        handleError(error)
        setSubmitting(false)
      }
    }
  }

  // This is needed in this context where we're using Formik, because even when the component
  // refreshes after loading the member, the initialValues with an empty role array will still
  // be locked in the Formik closure
  if (isLoading) {
    return (
      <Dialog open fullWidth maxWidth="sm">
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <CircularProgress />
          </Stack>
        </DialogContent>

        {hasLoadingError && (
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {LoadingErrorMessage}
            </Typography>
            <Button onClick={props.onClose}>{translate("button.close")}</Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        if (hasError) {
          resetError()
        }
        const errors: any = {}
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => submit(values, setSubmitting)}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
        const hasFormChanged: boolean = !deepEquals(values, initialValues)
        const canSubmit: boolean = !isSubmitting && hasFormChanged && Object.keys(errors).length === 0

        return (
          <Dialog open fullWidth maxWidth="sm" onClose={props.onClose}>
            <form onSubmit={handleSubmit} className="member-update-page-form">
              <DialogTitle>{translate("member.update.title")}</DialogTitle>

              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextLabel value={props.member.name} label={translate("label.name")} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextLabel label={translate("label.email")} value={props.member.email} />
                      </Grid>
                      <Grid item xs={6}>
                        <RoleSelect
                          id="member-update-page-field-roles"
                          // TODO: roles will maybe be multiple for members in the future, a
                          // change in behaviour will maybe be required
                          role={values.roles.length === 0 ? null : values.roles[0]}
                          availableRoles={ALL_MEMBER_ROLES}
                          onChange={newRoles => setFieldValue("roles", newRoles)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ justifyContent: "space-between" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5em" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button id="member-update-page-button-cancel" disabled={isSubmitting} variant="outlined" onClick={props.onClose}>
                    {translate("button.cancel")}
                  </Button>
                  <Button id="member-update-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.save")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}
