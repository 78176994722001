import { translate } from "app/language/service"
import { isValid } from "date-fns"

export function checkMinMaxLength(errors: any, fieldName: string, value: string, max: number, min?: number): void {
  if (value.length > max) {
    errors[fieldName] = translate("label.error.max.length", { maxLength: max })
  } else if (min !== undefined && value.length < min) {
    errors[fieldName] = translate("label.error.min.length", { minLength: min })
  }
}
export function validateDate(errors: any, fieldName: string, value: Date, min?: Date): void {
  if (!isValid(value)) {
    errors[fieldName] = translate("label.invalid.date")
  } else {
    if (min !== undefined && value < min) {
      errors[fieldName] = translate("label.error.min.date", { minDate: min.toDateString() })
    }
  }
}
