import { EventModule } from "app/event/EventModule"
import { JOrganizationEventDeleteApiKeyParams, JOrganizationEventDeleteInvitationParams, JOrganizationEventModule, ORGANIZATION_EVENTS } from "./model"

class OrganizationEventModule extends EventModule implements JOrganizationEventModule {
  public on = {
    apikeyDelete: (listenerId: string, fn: (params: JOrganizationEventDeleteApiKeyParams) => void): void => {
      this.addListenerFn(ORGANIZATION_EVENTS.DELETE_API_KEY, listenerId, fn)
    },
    invitationDelete: (listenerId: string, fn: (params: JOrganizationEventDeleteInvitationParams) => void): void => {
      this.addListenerFn(ORGANIZATION_EVENTS.DELETE_INVITATION, listenerId, fn)
    }
  }
}

export const organizationEventModule: OrganizationEventModule = new OrganizationEventModule()
