import { appEventModule } from "./event"
import { removeTokenInURL } from "./history/tools/common"
import { store } from "./store/store"

export function startAppListeners(): void {
  // TODO: I don't see why we are waiting for the store here
  // TODO: hash param management will maybe be removed
  try {
    // accessing the store when it's not initialized will throw
    store.getState()
    removeTokenInURL()
  } catch (error) {
    appEventModule.on.storeReady("app-store-ready", () => removeTokenInURL())
  }
}
