import { appEventModule } from "app/event"
import { store } from "app/store/store"
import { updateTheme } from "ui/tools/theme"
import { languageEventModule } from "./event"
import { JLANGUAGE_EVENTS } from "./model"
import { setCurrentLocaleFromContext } from "./tools/common"

export function startLanguageListeners(): void {
  const toProcess = () => {
    setCurrentLocaleFromContext()
    updateTheme()
    languageEventModule.notify(JLANGUAGE_EVENTS.INITIALIZED, {})
  }

  try {
    // accessing the store when it's not initialized will throw
    store.getState()
    toProcess()
  } catch (error) {
    appEventModule.on.storeReady("language-store-ready", () => toProcess())
  }
}
