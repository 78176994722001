import { JRootState } from "app/model"
import React from "react"
import { connect } from "react-redux"
import { ProjectCards } from "./ProjectCards"
import { ProjectGrid } from "./ProjectGrid"

interface JProjectMainContentConnectProps {
  displayCards: boolean
}

const ProjectMainContentFn = (props: JProjectMainContentConnectProps): JSX.Element => (props.displayCards ? <ProjectCards /> : <ProjectGrid />)

export const ProjectMainContent = connect(
  (state: JRootState) =>
    ({
      displayCards: state.project.displayCards
    } as JProjectMainContentConnectProps)
)(ProjectMainContentFn)
