import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Stack, Tab } from "@mui/material"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { FilePanel } from "file/components/FilePanel"
import { FILE_INFO_TYPES, JFileInfo } from "file/model"
import React from "react"
import { DATA_SOURCE_TABS } from "spatialdatasource/model"
import { reload, setActiveTab, setSdsToUpdate } from "spatialdatasource/store"
import { DataSourcePanel } from "./DataSourcePanel"
import { RasterDataSourceFormDialog } from "./RasterDataSourceFormDialog"
import { VectorDataSourceFormDialog } from "./VectorDataSourceFormDialog"
import { DataSourcePermissionDialog } from "./DataSourcePermissionDialog"

export const DataSourcePage = () => {
  // This is the FileInfo (either newly uploaded or coming from a row of the Files tab, raster or vector)
  // that will be used for the creation form
  const [currentFileInfos, setCurrentFileInfos] = React.useState<JFileInfo[]>([])
  const { sdsForWhichToEditPermissions } = useAppSelector(state => ({
    sdsForWhichToEditPermissions: state.sds.sdsForWhichToEditPermissions
  }))

  const { activeTab } = useAppSelector(state => ({
    activeTab: state.sds.activeTab
  }))
  const dispatch = useAppDispatch()

  const handleTabChange = (event: React.SyntheticEvent, tab: string) => {
    store.dispatch(setActiveTab(tab as DATA_SOURCE_TABS))
  }

  // This form is defined "above" the two tabs because it can be triggered from both
  const FormDialog = (currentFileInfos.length === 0 ? null : currentFileInfos[0].type === FILE_INFO_TYPES.VECTOR_DATA ? VectorDataSourceFormDialog : RasterDataSourceFormDialog) as React.ElementType

  return (
    <Stack padding="2rem" height="100%">
      {sdsForWhichToEditPermissions && <DataSourcePermissionDialog sds={sdsForWhichToEditPermissions} />}
      {currentFileInfos.length > 0 && (
        <FormDialog
          close={() => {
            setCurrentFileInfos([])
            dispatch(setSdsToUpdate(null))
          }}
          fileInfos={currentFileInfos}
          afterSubmit={() => {
            dispatch(reload())
            setCurrentFileInfos([])
            dispatch(setSdsToUpdate(null))
          }}
        />
      )}

      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{
              "& button": {
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 500,
                color: theme => theme.palette.text.primary
              },
              "& button.Mui-selected": {
                color: theme => theme.palette.primary.main
              }
            }}
            onChange={handleTabChange}
          >
            <Tab label={translate("sds.tab.sources")} value={DATA_SOURCE_TABS.SOURCES} />
            <Tab label={translate("sds.tab.files")} value={DATA_SOURCE_TABS.FILES} />
          </TabList>
        </Box>

        <TabPanel value={DATA_SOURCE_TABS.SOURCES} sx={{ height: "100%", paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
          <DataSourcePanel setCurrentFileInfos={setCurrentFileInfos} />
        </TabPanel>

        <TabPanel value={DATA_SOURCE_TABS.FILES} sx={{ height: "100%", paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
          <FilePanel setCurrentFileInfos={setCurrentFileInfos} />
        </TabPanel>
      </TabContext>
    </Stack>
  )
}
