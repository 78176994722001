import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { ALL_LOCALES } from "app/language/model"
import { languageSVC, translate } from "app/language/service"
import { copyToClipboard } from "app/utils/clipboard"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import { messageSVC } from "message/service"
import { JOrganizationInvitationCreateParams } from "organization/model"
import { createOrganizationInvitation } from "organization/tools/invitation"
import React from "react"
import { checkMinMaxLength } from "ui/tools/form"
import { RoleSelect } from "user/components/RoleSelect"
import { ALL_MEMBER_ROLES } from "user/model"
import { useErrorHandling } from "app/hook"

interface JOrganizationInvitationCreateDialogProps {
  onClose(): any
  onCreate(): any
}

export const OrganizationInvitationCreateDialog = (props: JOrganizationInvitationCreateDialogProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.invitation.create.submit.error"))
  const initialValues: JOrganizationInvitationCreateParams = {
    inviteeEmail: "",
    locale: languageSVC.getLocale(),
    roles: []
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        if (hasError) {
          resetError()
        }
        const errors: any = {}
        if (!values.inviteeEmail) {
          errors.inviteeEmail = translate("label.field.required")
        }
        if (!values.roles || values.roles.length === 0) {
          errors.roles = translate("label.field.required")
        }
        checkMinMaxLength(errors, "inviteeEmail", values.inviteeEmail, 255)
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (hasError) {
          resetError()
        }
        createOrganizationInvitation(values)
          .then(invitation => {
            setSubmitting(false)
            messageSVC.confirmDialog({
              title: translate("organization.invitation.message.title"),
              message: translate("organization.invitation.message.content"),
              confirmButtonLabel: translate("button.close"),
              hideCancelButton: true,
              children: (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem"
                  }}
                >
                  <Typography color="textPrimary" sx={{ marginRight: "1rem" }}>
                    {translate("organization.invitation.copy-to-clipboard")}
                  </Typography>
                  <Tooltip title={translate("label.copy.clipboard")}>
                    <IconButton size="small" onClick={() => copyToClipboard(`${invitation.invitationUrl}&locale=${invitation.locale}`)}>
                      <FontAwesomeIcon className="back-button" icon={light("link")} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ),
              onSuccess: () => {
                props.onCreate()
                props.onClose()
              },
              onCancel: () => props.onClose()
            })
          })
          .catch(error => {
            setSubmitting(false)
            console.error(error)
            handleError(error)
          })
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setTouched }) => {
        const hasInviteeEmailError: boolean = touched.inviteeEmail === true && errors.inviteeEmail !== undefined
        const rolesError: string = touched.roles === true && errors.roles !== undefined ? (errors.roles as string) : ""
        const hasChanged: boolean = !deepEquals(values, initialValues)
        const canSubmit: boolean = !isSubmitting && hasChanged && Object.keys(errors).length === 0
        return (
          <Dialog open fullWidth maxWidth="xs" id="organization-invitation-popup" onClose={props.onClose}>
            <form onSubmit={handleSubmit} className="organization-invitation-create-page-form">
              <DialogTitle>{translate("organization.invitation.create.title")}</DialogTitle>
              <DialogContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      id="organization-invitation-create-page-field-title"
                      name="inviteeEmail"
                      required
                      fullWidth
                      error={hasInviteeEmailError}
                      label={translate("label.invitee.email")}
                      value={values.inviteeEmail}
                      helperText={hasInviteeEmailError ? errors.inviteeEmail : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RoleSelect
                      id="organization-invitation-create-page-field-roles"
                      // name="roles" => set by setFieldValue function in onChange
                      required
                      // TODO: roles will maybe be multiple for members in the future, a change in behaviour will
                      // maybe be required
                      role={values.roles.length === 0 ? null : values.roles[0]}
                      availableRoles={ALL_MEMBER_ROLES}
                      error={rolesError}
                      onBlur={() => setTouched({ inviteeEmail: touched.inviteeEmail, roles: true })}
                      onChange={newRoles => setFieldValue("roles", newRoles)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField required name="locale" label={translate("label.communication.language")} value={values.locale} onChange={handleChange} fullWidth select>
                      {ALL_LOCALES.map(locale => (
                        <MenuItem key={locale} value={locale}>
                          {translate(locale)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ justifyContent: "space-between" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5em" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button id="organization-invitation-create-page-button-cancel" variant="outlined" disabled={isSubmitting} onClick={props.onClose}>
                    {translate("button.cancel")}
                  </Button>
                  <Button id="organization-invitation-create-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.create")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}
