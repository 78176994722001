import { Box, useTheme } from "@mui/material"
import React from "react"

// Grid cell must use that css class (cellClassName)
export const GRID_CELL_WITH_OVERFLOW_CLASS_NAME = "grid-cell-with-overflow"

export const GridCellOverflowBorder = (props: any) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: "30px",
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        background: `linear-gradient(90deg,  transparent, ${theme.palette.background.default})`
      }}
    />
  )
}
