import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { MessageCenter } from "message/components/MessageCenter"
import React from "react"
import { Outlet } from "react-router-dom"
import { MAIN_TITLE_HEIGHT_IN_REM, MENU_WIDTH_IN_REM } from "ui/model"
import { MainHeader } from "./MainHeader"
import { MainMenu } from "./MainMenu"

export const MainLayout = (): JSX.Element => (
  <Box
    sx={{
      width: "100%",
      minWidth: "100%",
      maxWidth: "100%",
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      display: "flex",
      justifyContent: "center",
      flexWrap: "nowrap",
      alignItems: "center",
      backgroundColor: theme => theme.palette.background.default
    }}
    className="main-container"
  >
    <MainMenu />
    <Box
      sx={{
        width: `calc(100% - ${MENU_WIDTH_IN_REM}rem)`,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <MainHeader />
      <Box
        sx={{
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
          zIndex: 1,
          height: `calc(100% - ${MAIN_TITLE_HEIGHT_IN_REM}rem)`,
          minHeight: `calc(100% - ${MAIN_TITLE_HEIGHT_IN_REM}rem)`,
          maxHeight: `calc(100% - ${MAIN_TITLE_HEIGHT_IN_REM}rem)`
        }}
        className="route-container"
      >
        <Outlet />
      </Box>
      <MessageCenter maxSize={true} />
    </Box>
    <Box sx={{ position: "absolute", bottom: 0, left: "50%", zIndex: 0, marginBottom: "0.2rem" }}>
      <Typography color="textSecondary" sx={{ fontSize: "0.75rem" }}>
        {`V${APP_VERSION}`}
      </Typography>
    </Box>
  </Box>
)
