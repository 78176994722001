import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { CONFIRM_BUTTON_ID, JAppConfirmDialogProps } from "message/model"
import { closeConfirmDialog } from "message/utils"
import React from "react"

export const ConfirmDialog = (props: JAppConfirmDialogProps) => {
  const isConfirmAutoFocus = !props.isCancelDefault && !props.isInputMessage
  const [input, setInput] = React.useState("")
  const onCancel = () => {
    closeConfirmDialog()
    if (typeof props.onCancel === "function") {
      try {
        props.onCancel()
      } catch (error) {
        console.error("An error occured while executing the onCancel handler of the ConfirMessage component", error)
      }
    }
  }
  const onSuccess = () => {
    closeConfirmDialog()
    try {
      props.onSuccess(input)
    } catch (error) {
      console.error("An error occured while executing the onSuccess handler of the ConfirMessage component", error)
    }
  }
  return (
    <Dialog id="jmap-confirm-message" open={true} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle
        id="jmap-confirm-message-title"
        sx={{
          minWidth: "20rem"
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="jmap-confirm-message-text">{props.message}</DialogContentText>
        {props.isInputMessage && (
          <TextField
            id="jmap-confirm-message-input"
            variant="filled"
            autoFocus={!props.isInputOptional}
            margin="dense"
            onChange={event => setInput(event.target.value)}
            onKeyUp={event => {
              event.stopPropagation()
              if (event.key === "Enter") {
                if (!input && !props.isInputOptional) {
                  return
                }
                onSuccess()
              }
            }}
            label={props.inputPlaceholder}
            fullWidth
          />
        )}
        {props.children}
      </DialogContent>
      <DialogActions>
        {!props.hideCancelButton && (
          <Button
            id="jmap-confirm-message-cancel-button"
            autoFocus={!isConfirmAutoFocus}
            onClick={onCancel}
            color="primary"
            // not sure about this
            // variant={!isConfirmAutoFocus ? "outlined" : undefined}>
            variant="outlined"
          >
            {props.cancelButtonLabel}
          </Button>
        )}
        <Button
          id={CONFIRM_BUTTON_ID}
          autoFocus={isConfirmAutoFocus}
          disabled={props.isInputMessage && !input && !props.isInputOptional}
          onClick={onSuccess}
          color="primary"
          // not sure about this
          // variant={isConfirmAutoFocus ? "contained" : undefined}
          variant="contained"
        >
          {props.confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
