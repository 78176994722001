import { JEventModule } from "app/event/model"
import { MEMBER_ROLES } from "user/model"

export enum MEMBER_EVENTS {
  DELETE = "delete"
}

export interface JMemberRepository {
  getAllMembers(organizationId: string): Promise<JMember[]>
  getById(memberId: string, organizationId: string): Promise<JMember>
  update(memberId: string, organizationId: string, params: JMemberUpdateParams): Promise<void>
  delete(memberId: string, organizationId: string, transferredOwnerId?: string): Promise<void>
}
export interface JMemberUpdateParams {
  roles: MEMBER_ROLES[]
}

export interface JMember {
  id: string
  name: string
  email: string
  organizationId: string
  roles: MEMBER_ROLES[]
}

export interface JMemberEventModule extends JEventModule {
  on: {
    delete(listenerId: string, fn: (params: JMemberEventDeleteParams) => void): void
  }
}

export interface JMemberEventDeleteParams {
  memberId: string
}
