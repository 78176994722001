import { store } from "app/store/store"
import { startUIListeners } from "./listeners"
import { JUIService } from "./model"
import { setDarkThemeActive } from "./tools/theme"

startUIListeners()

export const uiSVC: JUIService = {
  setDarkThemeActive,
  isDarkThemeActive(): boolean {
    return store.getState().ui.theme.palette.mode === "dark"
  }
}
