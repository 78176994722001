import { translate } from "app/language/service"
import { messageSVC } from "message/service"
import { organizationEventModule } from "organization/event"
import { JApiKey, JOrganizationApiKeyCreateParams, ORGANIZATION_EVENTS } from "organization/model"
import { organizationRPO } from "organization/repository"
import { ALL_API_KEY_ROLES, API_KEY_ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"

export function getAllOrganizationApiKeys(): Promise<JApiKey[]> {
  return organizationRPO.getApiKeys(getUserOrganization().id)
}

export function getOrganizationApiKeyCount(): Promise<number> {
  return getAllOrganizationApiKeys().then(apikeys => apikeys.length)
}

export function getOrganizationApiKeyById(apiKeyId: string): Promise<JApiKey> {
  return organizationRPO.getApiKey(getUserOrganization().id, apiKeyId)
}

export async function createOrganizationApiKey(params: JOrganizationApiKeyCreateParams): Promise<string> {
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (typeof params.title !== "string" || !params.title) {
    return Promise.reject("params.title must be a non empty string")
  }
  if (params.expiresAt && !(params.expiresAt instanceof Date)) {
    return Promise.reject("params.expireAt must be a date")
  }
  if (!Array.isArray(params.roles) || params.roles.length === 0) {
    return Promise.reject("params.roles must be a non empty array")
  }
  const invalidRole = params.roles.find(r => !ALL_API_KEY_ROLES.contains(r as API_KEY_ROLES))
  if (invalidRole) {
    return Promise.reject(`Invalid role "${invalidRole}". Available roles: ${ALL_API_KEY_ROLES.join(", ")}`)
  }
  try {
    const secret: string = await organizationRPO.createApiKey(getUserOrganization().id, params)
    messageSVC.success(translate("organization.apikey.create.success", { apiKeyName: params.title }))
    return secret
  } catch (error) {
    return Promise.reject(error)
  }
}

export function deleteOrganizationApikey(apikeyId: string): Promise<boolean> {
  if (typeof apikeyId !== "string" || !apikeyId) {
    return Promise.reject("apikeyId must be a non empty string")
  }
  return new Promise<boolean>((resolve, reject) => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.delete"),
      cancelButtonLabel: translate("button.cancel"),
      isCancelDefault: true,
      title: translate("organization.apikey.delete.title"),
      message: translate("organization.apikey.delete.message"),
      onCancel: () => resolve(false),
      onSuccess: () => {
        organizationRPO
          .deleteApiKey(getUserOrganization().id, apikeyId)
          .then(() => {
            messageSVC.success(translate("organization.apikey.delete.success"))
            organizationEventModule.notify(ORGANIZATION_EVENTS.DELETE_API_KEY, { apikeyId })
            resolve(true)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
    })
  })
}

export async function getOrganizationApiKeys(): Promise<JApiKey[]> {
  return organizationRPO.getApiKeys(getUserOrganization().id)
}
