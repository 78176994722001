import { Box } from "@mui/material"
import { JRootState } from "app/model"
import React from "react"
import { connect } from "react-redux"
import logoDark from "resources/images/logo-dark.png"
import logoLight from "resources/images/logo-light.png"
import simpleLogoDark from "resources/images/simple-logo-dark.png"
import simpleLogoLight from "resources/images/simple-logo-light.png"

export interface JMapCloudLogoProps extends JMapCloudLogoConnectProps {
  useSimpleImage?: boolean
  sx?: any
}

export interface JMapCloudLogoConnectProps {
  isDarkTheme: boolean
}

export const JMapCloudLogoFn = (props: JMapCloudLogoProps): JSX.Element => (
  <Box
    sx={{
      width: "7rem",
      height: "5.25rem",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${props.useSimpleImage ? (props.isDarkTheme ? simpleLogoDark : simpleLogoLight) : props.isDarkTheme ? logoDark : logoLight})`,
      ...props.sx
    }}
    className={"jaaz-logo"}
  />
)

export const JMapCloudLogo = connect(
  (state: JRootState) =>
    ({
      isDarkTheme: state.ui.theme.palette.mode === "dark"
    } as JMapCloudLogoConnectProps)
)(JMapCloudLogoFn)
