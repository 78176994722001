import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { store } from "app/store/store"
import { JAppMessage, MESSAGE_WIDTH_IN_REM } from "message/model"
import { setMessageExpired } from "message/store"
import React from "react"

export interface JMessageProps {
  message: JAppMessage
  maxSize?: boolean
}

export const AppMessage = (props: JMessageProps) => {
  const handleClose = () => {
    store.dispatch(setMessageExpired(props.message.id))
  }
  return (
    <Snackbar
      key={props.message.id}
      sx={{
        position: "relative",
        pointerEvents: "all",
        marginTop: "0.5rem"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      ClickAwayListenerProps={{ mouseEvent: false }}
      open={!props.message.expired}
      onClose={handleClose.bind(this)}
      autoHideDuration={props.message.duration}
    >
      <Alert
        sx={{
          width: `calc(${props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM} - 3rem)`,
          minWidth: `calc(${props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM} - 3rem)`,
          maxWidth: `calc(${props.maxSize ? "100%" : MESSAGE_WIDTH_IN_REM} - 3rem)`
        }}
        onClose={handleClose.bind(this)}
        variant="filled"
        severity={props.message.severity}
      >
        {`${props.message.text}`}
      </Alert>
    </Snackbar>
  )
}
