import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { JOrganization } from "organization/model"
import { EMPTY_TOKEN_VALUE, JTokenInfo, JUser, JUserState } from "user/model"

export const initialUserState: JUserState = {
  name: "",
  email: "",
  organizations: [],
  roles: [],

  accessToken: EMPTY_TOKEN_VALUE,
  accessTokenExpirationInMSecs: 0,
  idToken: { __raw: EMPTY_TOKEN_VALUE },
  picture: "",

  isLoggingIn: false, // this covers both user and org login
  isUserLoggedIn: false, // this is only for the user (selectedOrganization can still be null)
  userLoginError: "",
  organizationLoginError: "",
  selectedOrganization: null, // if this is null, it means that the user is not logged into an org
  switchOrganisationDialogIsOpen: false,

  callbackAtTokenRefresh: null // this callback, if defined, will be called at every timeout of `user.tools.auth0.keepTokenValid`
}

interface JLoginResult {
  success: boolean
  userError?: string
  organizationError?: string
}

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    startLogin(state) {
      state.isLoggingIn = true
      state.userLoginError = ""
      state.organizationLoginError = ""
    },
    endLogin(state, action: PayloadAction<JLoginResult>) {
      state.isLoggingIn = false
      state.isUserLoggedIn = action.payload.success
      state.userLoginError = action.payload.userError ?? ""
      state.organizationLoginError = action.payload.organizationError ?? ""
    },
    setUserInfo(state, action: PayloadAction<JUser & JTokenInfo>) {
      return { ...state, ...action.payload }
    },
    refreshAccessTokens(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    setUserOrganizations(state, action: PayloadAction<JOrganization[]>) {
      state.organizations = action.payload
    },
    selectUserOrganization(state, action: PayloadAction<JOrganization | null>) {
      state.selectedOrganization = action.payload
    },
    setCallbackAtTokenRefresh(state, action: PayloadAction<(() => void) | null>) {
      state.callbackAtTokenRefresh = action.payload
    },
    setSwitchOrganisationDialogIsOpen(state, action: PayloadAction<boolean>) {
      state.switchOrganisationDialogIsOpen = action.payload
    }
  }
})

export const { startLogin, endLogin, setUserInfo, refreshAccessTokens, setUserOrganizations, selectUserOrganization, setCallbackAtTokenRefresh, setSwitchOrganisationDialogIsOpen } = userSlice.actions

export default userSlice.reducer
