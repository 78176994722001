import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material"
import { translate } from "app/language/service"
import { formatDate } from "app/tool/date/tools/common"
import { JOrganizationInvitation } from "organization/model"
import React from "react"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { TextLabel } from "ui/components/TextLabel"
import { copyFieldValueInClipboard, getRoleChips } from "ui/utils"

interface JOrganizationInvitationDetailsDialogProps {
  invitation: JOrganizationInvitation
  onClose: () => any
}

export const OrganizationInvitationDetailsDialog = (props: JOrganizationInvitationDetailsDialogProps) => {
  const { invitation, onClose } = props
  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>{translate("organization.invitation.details.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextLabel value={invitation.invitee} label={translate("label.invitee")} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel value={invitation.inviter} label={translate("label.inviter")} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.createdAt")} value={invitation.expiresAt ? formatDate(invitation.createdAt, { displayTime: true, longFormat: true }) : ""} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.expiresAt")} value={invitation.expiresAt ? formatDate(invitation.expiresAt, { displayTime: true, longFormat: true }) : ""} />
              </Grid>
              <Grid item xs={6}>
                <RichTextLabel label={translate("label.roles")} value={getRoleChips(invitation.roles)} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.communication.language")} value={translate(invitation.locale ?? "en")} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel
                  label={translate("label.link")}
                  value={`${invitation.invitationUrl}&locale=${invitation.locale}`}
                  InputProps={{
                    inputProps: { style: { overflow: "hidden", textOverflow: "ellipsis" } },
                    endAdornment: (
                      <Tooltip title={translate("organization.invitation.copy-to-clipboard.tooltip")}>
                        <IconButton
                          size="small"
                          sx={{ minWidth: 40 }}
                          onClick={() => {
                            copyFieldValueInClipboard(`${invitation.invitationUrl}&locale=${invitation.locale}`, translate("label.link"))
                          }}
                        >
                          <FontAwesomeIcon icon={light("link")} />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate("button.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}
