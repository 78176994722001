import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { dateSVC } from "app/tool/date/service"
import { JOrganizationInvitation } from "organization/model"
import { organizationActionCreator } from "organization/store/actions"
import { getOrganizationInvitations } from "organization/tools/invitation"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { useHoverableDataGridRows } from "ui/hooks"
import { OrganizationInvitationCreateDialog } from "./OrganizationInvitationCreateDialog"
import { OrganizationInvitationDetailsDialog } from "./OrganizationInvitationDetailsDialog"
import { OrganizationInvitationGridRowMenu } from "./OrganizationInvitationGridRowMenu"

export const OrganizationInvitationGrid = (): JSX.Element => {
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [selectedInvitation, setSelectedInvitation] = React.useState<JOrganizationInvitation | null>(null)
  const [invitations, setInvitations] = React.useState<JOrganizationInvitation[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isCreateDialogOpened, setIsCreateDialogOpened] = React.useState(false)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const { pageSize } = useAppSelector(state => state.organization)

  React.useEffect(() => {
    getOrganizationInvitations()
      .then(fetchedInvitations => {
        setIsLoading(false)
        setInvitations(fetchedInvitations)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        setHasLoadingError(true)
      })
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: translate("label.id"), minWidth: 280, flex: 2 },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <OrganizationInvitationGridRowMenu
              onClose={() => setHoveredRowId(null)}
              invitationId={params.id as string}
              onShowEditDialog={() => {
                setSelectedInvitation(params.row)
              }}
              onDelete={reloadGrid}
            />
          )
        } else {
          return null
        }
      }
    },
    { field: "inviter", headerName: translate("label.inviter"), minWidth: 280, flex: 2 },
    { field: "invitee", headerName: translate("label.invitee"), minWidth: 280, flex: 2 },
    {
      field: "createdAt",
      headerName: translate("label.creationDate"),
      renderCell: params => dateSVC.format(params.row.createdAt),
      minWidth: 100,
      flex: 1
    },
    {
      field: "expiresAt",
      headerName: translate("label.expiresAt"),
      renderCell: params => dateSVC.format(params.row.expiresAt),
      minWidth: 100,
      flex: 1
    }
  ]

  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" justifyContent="end" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Button startIcon={<FontAwesomeIcon className="create-button" icon={regular("plus")} />} onClick={() => setIsCreateDialogOpened(true)}>
          {translate("organization.invitation.create.title")}
        </Button>
      </Stack>
      <PortalDataGrid
        rowType="organization.invitation"
        rows={invitations}
        pageSize={pageSize}
        onPageSizeChange={s => store.dispatch(organizationActionCreator.setPageSize(s))}
        pagination={false}
        columns={columns}
        className="invitation-list-data-grid"
        loading={isLoading}
        error={hasLoadingError}
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "invitee", sort: "asc" }]
          }
        }}
      />
      {selectedInvitation && <OrganizationInvitationDetailsDialog invitation={selectedInvitation} onClose={() => setSelectedInvitation(null)} />}
      {isCreateDialogOpened && <OrganizationInvitationCreateDialog onClose={() => setIsCreateDialogOpened(false)} onCreate={reloadGrid} />}
    </Stack>
  )
}
