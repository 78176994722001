import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { getLocale } from "app/language/tools/common"
import { copyToClipboard } from "app/utils/clipboard"
import { deepEquals } from "app/utils/common"
import { enUS, es, fr } from "date-fns/locale"
import { Formik } from "formik"
import { messageSVC } from "message/service"
import { JOrganizationApiKeyCreateParams } from "organization/model"
import { createOrganizationApiKey } from "organization/tools/apikey"
import React from "react"
import { checkMinMaxLength, validateDate } from "ui/tools/form"
import { RoleSelect } from "user/components/RoleSelect"
import { ALL_API_KEY_ROLES } from "user/model"

interface JOrganizationApiKeyCreateDialogProps {
  onClose(): any
  onCreate(): any
}

export const OrganizationApiKeyCreateDialog = (props: JOrganizationApiKeyCreateDialogProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.apikey.create.submit.error"))
  const initialValues: JOrganizationApiKeyCreateParams = {
    title: "",
    expiresAt: null,
    roles: []
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        if (hasError) {
          resetError()
        }
        const errors: any = {}
        if (!values.title) {
          errors.title = translate("label.field.required")
        }
        checkMinMaxLength(errors, "title", values.title, 50, 3)
        if (values.expiresAt !== null) {
          const today = new Date()
          const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
          validateDate(errors, "expiresAt", values.expiresAt, tomorrow)
        }
        if (values.roles.length === 0) {
          errors.roles = translate("label.roles.required")
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (hasError) {
          resetError()
        }
        createOrganizationApiKey(values)
          .then(secret => {
            setSubmitting(false)
            messageSVC.confirmDialog({
              title: translate("organization.apikey.important.message.title"),
              message: translate("organization.apikey.important.message.content"),
              children: (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem"
                  }}
                >
                  <Typography color="textPrimary" sx={{ marginRight: "1rem" }}>
                    {secret}
                  </Typography>
                  <Tooltip title={translate("label.copy.clipboard")}>
                    <IconButton size="small" onClick={() => copyToClipboard(secret)}>
                      <FontAwesomeIcon className="back-button" icon={light("copy")} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ),
              onSuccess: () => {
                props.onCreate()
                props.onClose()
              },
              onCancel: () => props.onClose()
            })
          })
          .catch(error => {
            setSubmitting(false)
            console.error(error)
            handleError(error)
          })
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
        const today = new Date()
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        const hasTitleError: boolean = touched.title === true && errors.title !== undefined
        const hasExpiresAtError: boolean = touched.expiresAt === true && errors.expiresAt !== undefined
        const hasChanged: boolean = !deepEquals(values, initialValues)
        const canSubmit: boolean = !isSubmitting && hasChanged && Object.keys(errors).length === 0
        return (
          <Dialog open fullWidth maxWidth="xs" id="organization-apikey-popup" onClose={props.onClose}>
            <form onSubmit={handleSubmit} className="organization-apikey-create-page-form">
              <DialogTitle>{translate("organization.apikey.create.title")}</DialogTitle>
              <DialogContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      id="organization-apikey-create-page-field-title"
                      name="title"
                      required
                      fullWidth
                      error={hasTitleError}
                      label={translate("label.title")}
                      value={values.title}
                      helperText={hasTitleError ? errors.title : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
                      <DatePicker
                        label={translate("label.expiresAt")}
                        sx={{ width: "100%" }}
                        value={values.expiresAt}
                        minDate={tomorrow}
                        onChange={date => setFieldValue("expiresAt", date)}
                        // TODO: https://k2geospatial.atlassian.net/browse/JMAP8-1663
                        // DatePicker props have changed
                        // renderInput={params => (
                        //   <TextField
                        //     {...params}
                        //     id="organization-apikey-create-page-field-expiresAt"
                        //     name="expiresAt"
                        //     fullWidth
                        //     error={hasExpiresAtError}
                        //     onBlur={handleBlur}
                        //     helperText={hasExpiresAtError ? errors.expiresAt : ""}
                        //   />
                        // )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <RoleSelect
                      id="organization-apikey-create-page-field-roles"
                      // an API key may not have more than one role at the present state, but this could change in the future
                      role={values.roles.length === 0 ? null : values.roles[0]}
                      required
                      availableRoles={ALL_API_KEY_ROLES}
                      onChange={newRoles => setFieldValue("roles", newRoles)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ justifyContent: "space-between" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5em" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button variant="outlined" disabled={isSubmitting} onClick={props.onClose}>
                    {translate("button.cancel")}
                  </Button>
                  <Button id="organization-apikey-create-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.create")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}

function getDateFnsLocale() {
  const locale = getLocale()
  if (locale === "fr") {
    return fr
  } else if (locale === "es") {
    return es
  } else {
    return enUS
  }
}
