import { JEventModule } from "app/event/model"
import { API_KEY_ROLES, ROLES } from "user/model"

export const enum ORGANIZATION_TABS {
  MAIN = "main",
  MEMBER = "member",
  INVITATION = "invitation",
  APIKEY = "apikey"
}

export const ALL_ORGANIZATION_TABS: ORGANIZATION_TABS[] = [ORGANIZATION_TABS.MAIN, ORGANIZATION_TABS.MEMBER, ORGANIZATION_TABS.INVITATION, ORGANIZATION_TABS.APIKEY]

export enum ORGANIZATION_EVENTS {
  DELETE = "delete",
  DELETE_API_KEY = "delete-apikey",
  DELETE_INVITATION = "delete-invitation"
}

export interface JOrganization {
  id: string
  auth0Id: string
  name: string
  active: boolean
  createdBy: string
  creationDate: Date
  lastModifiedBy: string
  lastModificationDate: Date
  externalApiKeys?: JExternalApiKey[]
}

export interface JOrganizationApiKeyCreateParams {
  title: string
  expiresAt: Date | null
  roles: ROLES[]
}

export interface JOrganizationUpdateParams {
  id: string
  name: string
}

export interface JOrganizationInvitationCreateParams {
  inviteeEmail: string
  locale: string
  roles: ROLES[]
}

export interface JOrganizationRepository {
  getOrganizationById(organizationId: string, accessToken?: string): Promise<JOrganization>
  update(params: JOrganizationUpdateParams): Promise<string>
  getApiKeys(organizationId: string): Promise<JApiKey[]>
  getApiKey(organizationId: string, apiKeyId: string): Promise<JApiKey>
  createApiKey(organizationId: string, params: JOrganizationApiKeyCreateParams): Promise<string>
  deleteApiKey(organizationId: string, apiKeyId: string): Promise<void>
  getOrganizationInvitations(organizationId: string): Promise<JOrganizationInvitation[]>
  getOrganizationInvitation(organizationId: string, invitationId: string): Promise<JOrganizationInvitation>
  createOrganizationInvitation(organizationId: string, params: JOrganizationInvitationCreateParams): Promise<JOrganizationInvitation>
  deleteOrganizationInvitation(organizationId: string, invitationId: string): Promise<void>
  getAllTags(organizationId: string): Promise<JTag[]>
  getFUSMetrics(organizationId: string): Promise<JFUSMetrics>
  getDASMetrics(organizationId: string): Promise<JDASMetrics>
  getMISMetrics(organizationId: string): Promise<JMISMetrics>
}

export interface JFUSMetrics {
  totalFileSize: number
  fileCount: number
}

export interface JDASMetrics {
  totalDataSize: number
}

export interface JMISMetrics {
  totalFileSize: number
  fileCount: number
}

export interface JOrganizationService {
  getUserOrganization(): JOrganization
}

export interface JOrganizationEventDeleteApiKeyParams {
  apikeyId: string
}

export interface JOrganizationEventDeleteInvitationParams {
  invitationId: string
}

export interface JOrganizationEventModule extends JEventModule {
  on: {
    apikeyDelete(listenerId: string, fn: (params: JOrganizationEventDeleteApiKeyParams) => void): void
    invitationDelete(listenerId: string, fn: (params: JOrganizationEventDeleteInvitationParams) => void): void
  }
}

export interface JApiKey {
  createdBy: string
  creationDate: Date
  id: string
  email: string
  title: string
  expiresAt: Date | null
  roles: API_KEY_ROLES[]
}

export interface JExternalApiKey {
  id: string
  title: string
  apiKey: string
  type: string
}

export interface JOrganizationInvitation {
  id: string
  inviter: string
  invitee: string
  locale: string
  createdAt: Date
  expiresAt: Date
  invitationUrl: string
  organizationId: string
  roles: ROLES[]
}

export interface JOrganizationState {
  activeTab: ORGANIZATION_TABS
  pageSize: number
}

export interface JTag {
  id: string
  organizationId: string
  name: string
}
