import { createSlice } from "@reduxjs/toolkit"
import { gridStateReducers, JDataGridState } from "ui/tools/grid"

type JJobState = JDataGridState

const initialJobState: JJobState = {
  page: 0,
  pageSize: 50,
  totalRowCount: 0,
  sortModel: [{ field: "updateDate", sort: "desc" }],
  filterModel: { items: [] },
  reloadCounter: 0
}

const jobSlice = createSlice({
  name: "job",
  initialState: initialJobState,
  reducers: gridStateReducers
})

export const { setPage, setPageSize, setTotalRowCount, setSortModel, setFilterModel, reload } = jobSlice.actions

export default jobSlice.reducer
