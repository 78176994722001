import { appCFG } from "app/config"
import { JLocalStorageService } from "./model"

try {
  if (!localStorage) {
    appCFG.localStorageAvailable = false
    console.warn("This browser doesn't support local storage")
  } else {
    localStorage.getItem("Should not throw if local storage OK")
  }
} catch (error) {
  appCFG.localStorageAvailable = false
  console.error("Browser doesn't support local storage", error)
}

export const localStorageSVC: JLocalStorageService = {
  isAvailable(): boolean {
    return appCFG.localStorageAvailable
  },
  get(key: string): string | null {
    if (!this.isAvailable()) {
      return null
    }
    return localStorage.getItem(key)
  },
  set(key: string, value: string): void {
    if (!this.isAvailable()) {
      return
    }
    localStorage.setItem(key, value)
  },
  remove(key: string): void {
    if (!this.isAvailable()) {
      return
    }
    localStorage.removeItem(key)
  }
}
