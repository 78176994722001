import { Checkbox, TableCell, Tooltip } from "@mui/material"
import { translate } from "app/language/service"
import React from "react"

interface PermissionCheckboxProps {
  isInherited: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  disabled: boolean
}

export const PermissionCheckbox = (props: PermissionCheckboxProps) => (
  <>
    {props.isInherited ? (
      <Tooltip
        title={translate("sds.permissions.inherited")}
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -25]
                }
              }
            ]
          }
        }}
      >
        <TableCell align="center" style={{ width: 100 }}>
          <Checkbox color="primary" disabled checked />
        </TableCell>
      </Tooltip>
    ) : (
      <TableCell align="center" style={{ width: 100 }}>
        <Checkbox
          color="primary"
          onChange={e => {
            props.onChange(e)
          }}
          disabled={props.disabled}
          checked={props.checked}
        />
      </TableCell>
    )}
  </>
)
