import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Masonry } from "@mui/lab"
import { Divider, Grid, Stack, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { store } from "app/store/store"
import { OrganizationApiKeyCreateDialog } from "organization/components/OrganizationApiKeyCreateDialog"
import { ORGANIZATION_TABS } from "organization/model"
import { getOrganizationApiKeyCount } from "organization/tools/apikey"
import { getOrganizationUsage } from "organization/tools/common"
import { setOrganizationActiveTab } from "organization/tools/display"
import { ORGANIZATION_PATH, PROJECT_PATH, SDS_PATH } from "page/model"
import { ProjectFormDialog } from "project/components/ProjectFormDialog"
import { setFormDialog } from "project/store"
import { getProjectCount } from "project/utils"
import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DATA_SOURCE_TABS } from "spatialdatasource/model"
import { setActiveTab } from "spatialdatasource/store"
import { getDataSourceCount } from "spatialdatasource/utils"
import { isCurrentUserAtLeastOrgAdmin, isCurrentUserAtLeastOrgEditor } from "user/tools/permissions"
import { DashboardDocumentationItem } from "./DashboardDocumentationItem"
import { DashboardEntityItem } from "./DashboardEntityItem"
import { DashboardUsageItem } from "./DashboardUsageItem"

interface JDashboardPageProps {
  isProjectFormDialogOpen: boolean
  isDarkTheme: boolean
}

const DashboardPageFn = (props: JDashboardPageProps): JSX.Element => {
  const [isCreateApikeyPopupOpened, setIsCreateApikeyPopupOpened] = React.useState(false)
  const navigate = useNavigate()
  return (
    <Stack
      sx={{
        padding: "2rem",
        height: "100%",
        overflowY: "auto"
      }}
    >
      <Grid container spacing={3}>
        {isCurrentUserAtLeastOrgEditor() && (
          <Grid item xs={12} md={4}>
            <DashboardEntityItem
              icon={<FontAwesomeIcon className="dashboard-icon" icon={light("map")} />}
              titleTranslationKey="label.projects"
              addLabel={translate("project.title.add")}
              onAdd={() => {
                store.dispatch(setFormDialog({ isOpen: true }))
              }}
              getCount={getProjectCount}
              onTitleClick={() => navigate(PROJECT_PATH)}
            />
          </Grid>
        )}
        {isCurrentUserAtLeastOrgEditor() && (
          <Grid item xs={12} md={4}>
            <DashboardEntityItem
              icon={<FontAwesomeIcon className="dashboard-icon" icon={light("database")} />}
              titleTranslationKey="label.sds"
              getCount={getDataSourceCount}
              onTitleClick={() => {
                navigate(SDS_PATH)
                store.dispatch(setActiveTab(DATA_SOURCE_TABS.SOURCES))
              }}
            />
          </Grid>
        )}
        {isCurrentUserAtLeastOrgAdmin() && (
          <Grid item xs={12} md={4}>
            <DashboardUsageItem
              icon={<FontAwesomeIcon className="dashboard-icon" icon={light("chart-line")} />}
              title={translate("label.usage.title")}
              getUsageInBytes={getOrganizationUsage}
              usageInBytesTranslationKey="label.usage"
            />
          </Grid>
        )}
        {isCurrentUserAtLeastOrgAdmin() && (
          <Grid item xs={12} md={4}>
            <DashboardEntityItem
              icon={<FontAwesomeIcon className="dashboard-icon" icon={light("share-nodes")} />}
              titleTranslationKey="label.apikeys"
              addLabel={translate("organization.apikey.title.add")}
              onAdd={() => setIsCreateApikeyPopupOpened(true)}
              getCount={getOrganizationApiKeyCount}
              onTitleClick={() => {
                navigate(ORGANIZATION_PATH)
                setOrganizationActiveTab(ORGANIZATION_TABS.APIKEY)
              }}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginTop: "1.4rem" }} />
      <Typography
        color="textPrimary"
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
          fontSize: "1.5rem",
          fontWeight: "bold"
        }}
      >
        {translate("documentation.title")}
      </Typography>
      <Masonry columns={{ xs: 1, md: 3 }} spacing={3}>
        <DashboardDocumentationItem
          mainText={translate("page.dashboard.portal-card.description")}
          linkText={translate("page.dashboard.portal-card.link-text")}
          linkUrl={translate("page.dashboard.portal-card.link")}
        />
        <DashboardDocumentationItem
          mainText={translate("page.dashboard.api-card.description")}
          linkText={translate("page.dashboard.api-card.link-text")}
          linkUrl={translate("page.dashboard.api-card.link")}
        />
        <DashboardDocumentationItem
          mainText={translate("page.dashboard.ng-card.description")}
          linkText={translate("page.dashboard.ng-card.link-text")}
          linkUrl={translate("page.dashboard.ng-card.link")}
        />
        <DashboardDocumentationItem
          mainText={translate("page.dashboard.blog-card.description")}
          linkText={translate("page.dashboard.blog-card.link-text")}
          linkUrl={translate("page.dashboard.blog-card.link")}
        />
      </Masonry>
      {props.isProjectFormDialogOpen && (
        <ProjectFormDialog
          project={null}
          onClose={() => store.dispatch(setFormDialog({ isOpen: false }))}
          onCreate={() => {
            store.dispatch(setFormDialog({ isOpen: false }))
            navigate(PROJECT_PATH)
          }}
        />
      )}

      {isCreateApikeyPopupOpened && (
        <OrganizationApiKeyCreateDialog
          onClose={() => setIsCreateApikeyPopupOpened(false)}
          onCreate={() => {
            navigate(ORGANIZATION_PATH)
            setOrganizationActiveTab(ORGANIZATION_TABS.APIKEY)
          }}
        />
      )}
    </Stack>
  )
}

export const DashboardPage = connect(
  (state: JRootState) =>
    ({
      isProjectFormDialogOpen: state.project.isFormDialogOpen,
      isDarkTheme: state.ui.theme.palette.mode === "dark"
    } as JDashboardPageProps)
)(DashboardPageFn)
