import { getAllPages } from "page/tools/start"
import { JPage, JPageState } from "../model"

export function getDefaultPageState(): JPageState {
  const allPages: JPage[] = getAllPages(SHOW_STUDIO)
  return {
    pages: allPages,
    activePage: allPages[0]
  }
}
