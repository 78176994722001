import proj4 from "proj4"
import proj4Definitions from "resources/json/proj4.json"
import projections from "resources/json/projections.json"
import { JProjection, JProjectionService } from "./model"

proj4.defs(proj4Definitions.projections)

const ALL_EPSG: string[] = proj4Definitions.projections.map((item: string[]) => item[0])

export const projectionSVC: JProjectionService = {
  getAllEPSG(): string[] {
    return ALL_EPSG.slice()
  },
  isValidEPSG(epsg: string): boolean {
    return ALL_EPSG.contains(epsg)
  },
  getProjections(): JProjection[] {
    return projections.values
  }
}
