import { InputAdornment, InputProps, StandardTextFieldProps, TextField, TextFieldProps, useTheme } from "@mui/material"
import React from "react"

export interface RichTextLabelProps extends StandardTextFieldProps {
  value: JSX.Element | JSX.Element[]
}

// This is just a wrapper around a readonly Textfield component, to make sure that we maintain a coherent visual style
// This variant only accepts non string or number values, as they are placed in the input endorment section
export const RichTextLabel = (props: RichTextLabelProps) => {
  const theme = useTheme()
  const textfieldProps: TextFieldProps = {
    ...props,
    InputProps: {
      ...props.InputProps,
      readOnly: true,
      disableUnderline: true
    } as InputProps,
    InputLabelProps: {
      ...props.InputLabelProps,
      focused: false,
      shrink: true
    }
  }
  textfieldProps.InputProps!.startAdornment = (
    <InputAdornment
      style={{
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        color: theme.palette.text.primary,
        overflowX: "scroll",
        overflowY: "hidden"
      }}
      position="start"
    >
      {props.value}
    </InputAdornment>
  )
  if (!textfieldProps.InputProps!.inputProps) {
    textfieldProps.InputProps!.inputProps = {}
  }
  if (!textfieldProps.InputProps!.inputProps.style) {
    textfieldProps.InputProps!.inputProps.style = {}
  }
  textfieldProps.InputProps!.inputProps.style = {
    ...textfieldProps.InputProps!.inputProps?.style,
    maxWidth: "0px",
    minWidth: "0px",
    width: "0px"
  }
  delete textfieldProps.value
  return <TextField {...textfieldProps} fullWidth />
}
