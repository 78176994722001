import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon, Menu, MenuItem, Theme, Tooltip, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { JOrganization } from "organization/model"
import { JPage, LOGIN_PATH } from "page/model"
import { activatePageByPathInStore } from "page/tools/common"
import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MENU_WIDTH_IN_REM } from "ui/model"
import { isActiveMenu } from "ui/tools/menu"
import { doesCurrentUserHasAtLeastRole } from "user/tools/permissions"
import { JMapCloudLogo } from "./JMapCloudLogo"

interface JMainMenuProps {
  isUserLoggedIn: boolean
  allPages: JPage[]
  activePage: JPage
  userSelectedOrganization: JOrganization | null
}

const JMC_API_URL = "https://jmapcloud.readme.io/"

const HelpButtonMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  return (
    <div>
      <Box
        className={"menu-link"}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
          setTooltipOpen(false)
        }}
      >
        <Tooltip
          open={tooltipOpen}
          disableHoverListener
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          title={translate("main-menu.help.tooltip")}
          placement="left"
        >
          <div>
            <FontAwesomeIcon
              icon={regular("question")}
              style={{
                width: "1.5rem",
                height: "1.5rem"
              }}
            />
          </div>
        </Tooltip>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            window.open(translate("page.dashboard.portal-card.link"), "_blank")
            handleClose()
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={regular("info-circle")} />
          </ListItemIcon>
          {translate("help.jmc.portal.user.guide")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(JMC_API_URL, "_blank")
            handleClose()
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={regular("file-alt")} />
          </ListItemIcon>
          {translate("help.jmc.api")}
        </MenuItem>
      </Menu>
    </div>
  )
}

export const MainMenuFn = (props: JMainMenuProps) => {
  const theme: Theme = useTheme()
  const navigate = useNavigate()
  const allPages = props.allPages.filter(p => props.isUserLoggedIn && props.userSelectedOrganization && p.path !== LOGIN_PATH && p.icon)
  return (
    <Box
      sx={{
        "paddingTop": "0.5rem",
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": `${MENU_WIDTH_IN_REM}rem`,
        "minWidth": `${MENU_WIDTH_IN_REM}rem`,
        "maxWidth": `${MENU_WIDTH_IN_REM}rem`,
        "height": "100%",
        "minHeight": "100%",
        "maxHeight": "100%",
        "background": theme.palette.background.paper,
        "borderRight": `1px solid ${theme.palette.divider}`,
        "& .menu-link": {
          padding: ".8rem",
          color: theme.palette.text.secondary
        },
        "& .menu-link:hover": {
          color: theme.palette.text.primary,
          cursor: "pointer"
        },
        "& .menu-link.active": {
          color: theme.palette.text.primary
        },
        "& .menu-link.disabled": {
          cursor: "not-allowed",
          color: theme.palette.text.disabled
        },
        "& .menu-link.inactive": {
          cursor: "default",
          color: theme.palette.text.disabled
        }
      }}
    >
      <Box
        sx={{
          "display": "flex",
          "height": "100%",
          "flexDirection": "column",
          "justifyContent": "start",
          "& > *:last-child": {
            marginTop: allPages.length > 0 ? "auto" : ".4rem"
          },
          "alignItems": "center"
        }}
      >
        <JMapCloudLogo
          sx={{
            marginTop: ".4rem",
            marginBottom: "2.5rem",
            width: "4.125rem",
            height: "2.375rem"
          }}
          useSimpleImage={false}
        />
        {allPages.map((page: JPage) => {
          const userHasOrgPermission = doesCurrentUserHasAtLeastRole(page.minimumRequiredRole)
          return (
            <Tooltip title={userHasOrgPermission ? translate(page.titleTranslationKey) : translate("user.organization.member.forbidden.access")} key={page.path} placement="left">
              <Box
                className={`menu-link ${!userHasOrgPermission ? "disabled" : isActiveMenu(page, props.activePage) ? "active" : ""}`}
                onClick={() => {
                  if (userHasOrgPermission) {
                    activatePageByPathInStore(page.path)
                    navigate(page.path)
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={page.icon as IconDefinition}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem"
                  }}
                />
              </Box>
            </Tooltip>
          )
        })}
      </Box>
      <HelpButtonMenu />
    </Box>
  )
}

export const MainMenu = connect(
  (state: JRootState) =>
    ({
      isUserLoggedIn: state.user.isUserLoggedIn,
      userSelectedOrganization: state.user.selectedOrganization,
      allPages: state.page.pages,
      activePage: state.page.activePage
    } as JMainMenuProps)
)(MainMenuFn)
