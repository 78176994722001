import { JEventModule } from "app/event/model"

export const REDIRECT_URL_REGEX = new RegExp(/&|\?/, "ig")
export const SHORT_SERVER_VERSION_REGEX = new RegExp(/([0-9]+)\.[0-9]+\s+(\S+)\b.*/, "ig")

export const enum JSERVER_MICRO_SERVICE_STATUSES {
  STARTING = "STARTING",
  UP = "UP",
  DOWN = "DOWN"
}

export const enum JSERVER_IDENTITY_PROVIDER_TYPES {
  AUTH0_SPA = "auth0-spa",
  JMAP_CLOUD = "jmapcloud.io" // reserved for future usage ("standard login" via REST API)
}

export enum JSERVER_MICRO_SERVICE_IDS {
  GATEWAY = "gateway",
  SECURITY = "ss",
  PROCESS = "ps",
  CONFIGURATION = "mcs",
  DAS = "das",
  MVT_CACHE = "vtcs",
  DATA_INTEGRATION = "dis",
  FILE_UPLOAD = "fus",
  MAP_IMAGE = "mis",
  EXTENSION = "es"
}

export enum JSERVER_EVENTS {
  INFO_READY = "info-ready"
}

export enum JSERVER_MAIN_VERSIONS {
  V0,
  V1
}

export const DEFAULT_SERVER_VERSION: string = "JMap Cloud"
export const CURRENT_SERVER_MAIN_VERSION_AS_STRING: string = "JMap Cloud"
export const CURRENT_SERVER_MAIN_VERSION: JSERVER_MAIN_VERSIONS = JSERVER_MAIN_VERSIONS.V0
export const CURRENT_SERVER_BUILD_NUMBER: number = 0

export interface JServerVersion {
  title: string
  mainVersion: number
  buildNumber: number
}

export interface JServerMicroService {
  id: string
  name: string
  version: string
  status: JSERVER_MICRO_SERVICE_STATUSES
  restBaseUrl: string
}

export interface JServerMicroServiceById {
  [id: string]: JServerMicroService
}

export interface JServerInfo {
  version: JServerVersion
  microServiceById: JServerMicroServiceById
  identityProviders: JServerAnyIdentityProvider[]
}

export type JServerAnyIdentityProvider = JServerIdentityProviderJMapCloudNative | JServerIdentityProviderAuth0Spa

export interface JServerIdentityProviderBase {
  id: string
  name: string
  type: JSERVER_IDENTITY_PROVIDER_TYPES
}

export interface JServerIdentityProviderJMapCloudNative extends JServerIdentityProviderBase {
  type: JSERVER_IDENTITY_PROVIDER_TYPES.JMAP_CLOUD
}

export interface JServerIdentityProviderAuth0Spa extends JServerIdentityProviderBase {
  type: JSERVER_IDENTITY_PROVIDER_TYPES.AUTH0_SPA
  domain: string
  clientId: string
  audience: string
  logoutRedirectUrl: string
}

export interface JServerRepository {
  getInfo(): Promise<JServerInfo>
}

export interface JServerState extends JServerInfo {
  isReady: boolean
  isLoading: boolean
  hasLoadingError: boolean
}

export interface JServerService {
  isReady(): boolean
  getVersion(): JServerVersion
}

export interface JServerEventModule extends JEventModule {
  on: {
    infoReady(listenerId: string, fn: (params: JServerInfo) => void): void
  }
}
