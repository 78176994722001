import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon, MenuItem } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import { languageSVC, translate } from "app/language/service"
import { messageSVC } from "message/service"
import React from "react"
import { userSVC } from "user/service"

export function LocalesPopupMenu() {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null)
  const handleClose = () => setAnchorElement(null)
  const locales = languageSVC.getLocales()
  const currentLocale = languageSVC.getLocale()
  return (
    <div className="jmap-locales-menu">
      <IconButton
        id={"jmap-locales-menu-button"}
        color="primary"
        aria-label="more"
        aria-controls="jmap-locales-menu"
        aria-haspopup="true"
        onClick={(event: any) => setAnchorElement(event.currentTarget)}
      >
        <FontAwesomeIcon icon={solid("language")} />
      </IconButton>
      <Menu id="jmap-locales-menu" open={Boolean(anchorElement)} anchorEl={anchorElement} onClose={() => handleClose()} variant="menu">
        {locales.map((locale, index) => (
          <MenuItem
            sx={{ paddingLeft: "0.5rem" }}
            key={`jmap-locales-menu-item-${index}`}
            selected={currentLocale === locale}
            onClick={() => {
              if (locale !== currentLocale) {
                if (userSVC.isLoggedIn()) {
                  messageSVC.confirmDialog({
                    isCancelDefault: true,
                    confirmButtonLabel: translate("button.yes", undefined, locale),
                    cancelButtonLabel: translate("button.no", undefined, locale),
                    message: translate("user.change.locale.warning", undefined, locale),
                    title: translate("user.change.locale.title", undefined, locale),
                    locale,
                    onSuccess: () => languageSVC.setLocale(locale)
                  })
                } else {
                  languageSVC.setLocale(locale)
                }
              }
              handleClose()
            }}
          >
            <ListItemIcon sx={{ minWidth: "1.5rem" }}>{currentLocale === locale && <FontAwesomeIcon icon={solid("check")} />}</ListItemIcon>
            {translate(`user.locale.${locale}`, undefined, locale)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
