import englishLocale from "date-fns/locale/en-US"
import spanishLocale from "date-fns/locale/es"
import frenchLocale from "date-fns/locale/fr"
import { LOCALES } from "jmapcloud-types"

export const DATE_FNS_DATE_FORMAT_BY_JMAP_LOCALE: { [locale: string]: string } = {
  en: "MM/dd/yyyy",
  fr: "dd/MM/yyyy",
  es: "dd/MM/yyyy"
}

export const DATE_FNS_LONG_DATE_FORMAT_BY_JMAP_LOCALE: { [locale: string]: string } = {
  en: "PPPP",
  fr: "PPPP",
  es: "PPPP"
}

export const DATE_FNS_DATETIME_FORMAT_BY_JMAP_LOCALE: { [locale: string]: string } = {
  en: "MM/dd/yyyy hh:mm:ss aaa",
  fr: "dd/MM/yyyy HH:mm:ss",
  es: "dd/MM/yyyy HH:mm:ss"
}

export const DATE_FNS_LONG_DATETIME_FORMAT_BY_JMAP_LOCALE: { [locale: string]: string } = {
  en: "PPPP 'at' hh:mm:ss aaa",
  fr: "PPPP 'à' HH:mm:ss",
  es: "PPPP 'a las' HH:mm:ss"
}

export const IS_12_HOURS_TIME_BY_JMAP_LOCALE: { [locale: string]: boolean } = {
  en: true,
  fr: false,
  es: false
}

export const DATE_FNS_LOCALE_BY_JMAP_LOCALE: { [locale: string]: any } = {
  en: englishLocale,
  fr: frenchLocale,
  es: spanishLocale
}

export type JUtilsByLocale = { [T in LOCALES]: any }

export type JDateLike = number | Date | string
export type JDateUnit = "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "years"

export interface JDateFormatParams {
  displayTime?: boolean
  longFormat?: boolean
  prefix?: string
}

export interface JDateService {
  getDate(date: JDateLike): Date
  getDateFnsLocale(displayTime?: boolean): any
  is12HoursTimeFormat(): boolean
  getDateFromISOString(isoDate: string): Date
  add(date: JDateLike, amount: number, timeUnit: JDateUnit): Date
  substract(date: JDateLike, amount: number, timeUnit: JDateUnit): Date
  format(date: JDateLike, params?: JDateFormatParams): string
  formatDistanceToNow(date: JDateLike, params?: JDateFormatParams): string
  isBefore(date1: JDateLike, date2: JDateLike, checkTime?: boolean): boolean
  isAfter(date1: JDateLike, date2: JDateLike, checkTime?: boolean): boolean
}
