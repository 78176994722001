import { getMembersAndRoles } from "member/tools/common"
import { getOrganizationApiKeys } from "organization/tools/apikey"
import * as React from "react"
import { MEMBER_ROLES } from "user/model"
import { JAcl, JAPIKeyAcl, JMemberAcl } from "./model"
import { getUsersDataSourcePermissions } from "./utils"

function getOrgRoleAvatar(role: MEMBER_ROLES): string {
  return role[4]
}

export function useSdsAcls(sdsId: string): [JAcl[], React.Dispatch<React.SetStateAction<JAcl[]>>] {
  const [acls, setAcls] = React.useState<JAcl[]>([])

  React.useEffect(() => {
    const getMembersAndApiKeys = async () => {
      const membersAndRoles = await getMembersAndRoles()
      const memberAcls: JMemberAcl[] = membersAndRoles.map(m => ({
        ...m,
        roles: m.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: m.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: false
      }))
      const fetchedApiKeys = await getOrganizationApiKeys()
      const apiKeyAcls: JAPIKeyAcl[] = fetchedApiKeys.map(k => ({
        ...k,
        name: k.title,
        roles: k.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: k.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: true
      }))
      const membersAndApiKeysAcls: JAcl[] = [...memberAcls, ...apiKeyAcls]
      const sdsAcls = await getUsersDataSourcePermissions(sdsId)
      const sdsAclsByPrincipal = Object.fromEntries(sdsAcls.map(a => [a.principal, a]))
      const memberAndProjectAcls = membersAndApiKeysAcls.map(a => {
        const sdsAcl = sdsAclsByPrincipal[a.principal]
        return sdsAcl ? { ...a, ...sdsAcl, isAcl: true } : a
      })
      memberAndProjectAcls.sort((a1, a2) => a1.name.localeCompare(a2.name))
      setAcls(memberAndProjectAcls)
    }
    getMembersAndApiKeys()
  }, [])
  return [acls, setAcls]
}
