import { store } from "app/store/store"
import { serverEventModule } from "server/event"
import { JSERVER_EVENTS, JSERVER_MICRO_SERVICE_IDS, JSERVER_MICRO_SERVICE_STATUSES } from "server/model"
import { serverRPO } from "server/repository"
import { serverActionCreator } from "server/store/actions"

export function isServerReady(): boolean {
  return store.getState().server.isReady
}

export function checkServerReady(): void {
  if (!isServerReady) {
    throw Error("Server is not yet ready")
  }
}

export function initServerConnection(): Promise<void> {
  return new Promise(resolve => {
    const connectToServer = () => {
      getServerInfo()
        .then(() => resolve())
        .catch(reason => {
          console.error("could not get server info. Will try again in 5 seconds. Error: ", reason)
          setTimeout(() => connectToServer(), 5000)
        })
    }
    connectToServer()
  })
}

function getServerInfo(): Promise<void> {
  store.dispatch(serverActionCreator.setServerInfoIsLoading(true))
  store.dispatch(serverActionCreator.setServerInfoLoadingError(false))
  return new Promise((resolve, reject) => {
    const delayedReject = (error: any) => {
      setTimeout(() => {
        // leave time to display loader in NG login interface
        store.dispatch(serverActionCreator.setServerInfoIsLoading(false))
        store.dispatch(serverActionCreator.setServerInfoLoadingError(true))
        reject(error)
      }, 2000)
    }
    serverRPO
      .getInfo()
      .then(serverInfo => {
        if (!serverInfo.microServiceById) {
          return delayedReject("No service on server")
        }
        const allMicroServices = Object.values(serverInfo.microServiceById)
        let isAnnyServiceInError = false
        for (const microService of allMicroServices) {
          if (microService.status !== JSERVER_MICRO_SERVICE_STATUSES.UP) {
            isAnnyServiceInError = true
            console.error(`Service "${microService.id}" is not ready`)
          }
        }
        if (isAnnyServiceInError) {
          return delayedReject("Server not ready")
        }
        store.dispatch(serverActionCreator.setServerInfo(serverInfo))
        store.dispatch(serverActionCreator.setServerInfoIsLoading(false))
        store.dispatch(serverActionCreator.setServerInfoLoadingError(false))
        serverEventModule.notify(JSERVER_EVENTS.INFO_READY, serverInfo)
        resolve()
      })
      .catch(error => delayedReject(error))
  })
}

export function getMicroServiceBaseUrlById(id: JSERVER_MICRO_SERVICE_IDS): string {
  const service = store.getState().server.microServiceById[id]
  if (!service) {
    throw Error(`Service "${id}" not found in server configuration`)
  }
  return service.restBaseUrl.endsWith("/") ? service.restBaseUrl.slice(0, -1) : service.restBaseUrl
}

export function getRestBaseUrl(): string {
  return window.JMAP_OPTIONS.baseUrl || location.origin
}

export function getNgBaseUrl(): string {
  return getRestBaseUrl().replace(/(https?):\/\/[^.]+[.](.+)/, "$1://ng.$2")
}
