import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { ajaxSVC } from "app/ajax/service"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JDataGridPagedResponse, getFormattedGridQueryForJMC } from "ui/tools/grid"
import { JFileInfo, JFileRepository } from "./model"

export const fileRPO: JFileRepository = {
  get(organizationId: string, page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JFileInfo>> {
    return new Promise<JDataGridPagedResponse<JFileInfo>>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.FILE_UPLOAD)}/rest/v1/organizations/${organizationId}/files`,
            params: {
              page: page + 1, // watch out API's page is 1-indexed
              size,
              q: getFormattedGridQueryForJMC(filterModel, sortModel, "fileName")
            }
          })
          .then((response: any) => {
            const apiResponse = {
              page: response.page,
              result: response.result.map((rawFileInfo: any) => getFileInfoFromRaw(rawFileInfo))
            }
            resolve(apiResponse)
          })
          .catch((error: any) => {
            console.error(`Server error while getting all files of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all files of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  getOne(organizationId: string, fileId: string): Promise<JFileInfo> {
    return new Promise<any>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.FILE_UPLOAD)}/rest/v1/organizations/${organizationId}/files/${fileId}`
          })
          .then((response: any) => resolve(response.result))
          .catch((error: any) => {
            console.error(`Server error while getting the info for file "${fileId}" of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting the info for file "${fileId}" of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  delete(organizationId: string, fileId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.FILE_UPLOAD)}/rest/v1/organizations/${organizationId}/files/${fileId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting file "${fileId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while deleting file "${fileId}"`, ex)
        reject(ex)
      }
    })
  }
}

function getFileInfoFromRaw(rawFileInfo: any): JFileInfo {
  return {
    ...rawFileInfo
  }
}
