import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { gridStateReducers } from "ui/tools/grid"
import { DATA_SOURCE_TABS, JDataSource, JDataSourceState } from "./model"

const initialDataSourceState: JDataSourceState = {
  activeTab: DATA_SOURCE_TABS.SOURCES,
  sdsToUpdate: null,
  sdsForWhichToEditPermissions: null,

  page: 0,
  pageSize: 50,
  totalRowCount: 0,
  sortModel: [{ field: "name", sort: "asc" }],
  filterModel: { items: [] },
  reloadCounter: 0,
  sdsToDisplayDetails: null
}

const sdsSlice = createSlice({
  name: "sds",
  initialState: initialDataSourceState,
  reducers: {
    ...{
      setActiveTab(state: JDataSourceState, action: PayloadAction<DATA_SOURCE_TABS>) {
        state.activeTab = action.payload
      },
      setSdsToUpdate(state: JDataSourceState, action: PayloadAction<JDataSource | null>) {
        state.sdsToUpdate = action.payload
      },
      setSdsPermissionDialog(state: JDataSourceState, action: PayloadAction<JDataSource | null>) {
        state.sdsForWhichToEditPermissions = action.payload
      },
      setSdsToDisplayDetails(state: JDataSourceState, action: PayloadAction<JDataSource | null>) {
        state.sdsToDisplayDetails = action.payload
      }
    },
    ...gridStateReducers
  }
})

export const {
  setActiveTab,
  setSdsToUpdate,
  setSdsPermissionDialog,
  setSdsToDisplayDetails,

  setPage,
  setPageSize,
  setTotalRowCount,
  setSortModel,
  setFilterModel,
  reload
} = sdsSlice.actions

export default sdsSlice.reducer
