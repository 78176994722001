import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, IconButton, TextField, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { translate } from "app/language/service"
import React from "react"

interface JInlineEditableTextFieldProps {
  DisplayComponent: any
  DisplayComponentChild?: any
  DisplayComponentProps?: any
  TextFieldProps?: any
  initialValue: string
  disabled?: boolean
  required: boolean
  multiline?: boolean
  onAcceptEdit: (value: any) => void
}

export const InlineEditableTextField = (props: JInlineEditableTextFieldProps) => {
  const [value, setValue] = React.useState(props.initialValue)
  const [isEditable, setIsEditable] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const theme = useTheme()

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus()
    }
  }, [isEditable])

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !props.multiline) {
      e.preventDefault()
      acceptEdit()
    } else if (e.key === "Escape") {
      e.preventDefault()
      rejectEdit()
    }
  }

  const acceptEdit = () => {
    if (hasError) {
      return
    }
    props.onAcceptEdit(value)
    setIsEditable(false)
    setIsHovered(false)
  }

  const rejectEdit = () => {
    setValue(props.initialValue)
    setIsEditable(false)
    setIsHovered(false)
  }

  const handleChange = (e: any) => {
    const v = e.target.value
    setHasError(!props.required && v === "")
    setValue(v)
  }

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Stack direction="row" alignItems="center">
        {isEditable && !props.disabled ? (
          <TextField
            {...props.TextFieldProps}
            inputRef={inputRef}
            fullWidth
            error={hasError}
            helperText={hasError ? translate("label.field.required") : ""}
            onKeyDown={handleKeyDown}
            multiline={props.multiline}
            value={value}
            onBlur={rejectEdit}
            onChange={handleChange}
          />
        ) : (
          <props.DisplayComponent {...props.DisplayComponentProps} onClick={() => setIsEditable(true)}>
            {props.DisplayComponentChild}
          </props.DisplayComponent>
        )}
        {isHovered && !isEditable && !props.disabled && (
          <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onClick={() => setIsEditable(true)}>
            <FontAwesomeIcon size="xs" icon={light("pencil")} />
          </IconButton>
        )}
        {isEditable && !props.disabled && (
          <>
            {/* onMouseDown instead of onClick is needed here because if not, the onBlur of the TextField is called first */}
            <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onMouseDown={acceptEdit}>
              <FontAwesomeIcon icon={light("check")} />
            </IconButton>
            <IconButton sx={{ color: theme.palette.text.secondary }} size="small" onClick={rejectEdit}>
              <FontAwesomeIcon icon={light("x")} />
            </IconButton>
          </>
        )}
      </Stack>
    </Box>
  )
}
