import { getGridDateOperators, getGridStringOperators, GridFilterItem, GridFilterModel, GridFilterOperator, GridSortItem, GridSortModel } from "@mui/x-data-grid"
import { PayloadAction } from "@reduxjs/toolkit"
import { translate } from "app/language/service"

export interface JDataGridPagedResponse<Entity> {
  page: {
    number: number
    size: number
    totalElements: number
    totalPages: number
  }
  result: Entity[]
}

export interface JDataGridState {
  page: number
  pageSize: number
  totalRowCount: number
  sortModel: GridSortModel
  filterModel: GridFilterModel
  reloadCounter: number // this is incremented by the reload action (to be detected as a change in a useEffect, to trigger reload)
}

export const gridStateReducers = {
  setPage(state: JDataGridState, action: PayloadAction<number>) {
    state.page = action.payload
  },
  setPageSize(state: JDataGridState, action: PayloadAction<number>) {
    state.pageSize = action.payload
    state.page = 0
  },
  setTotalRowCount(state: JDataGridState, action: PayloadAction<number>) {
    state.totalRowCount = action.payload
  },
  setSortModel(state: JDataGridState, action: PayloadAction<GridSortModel>) {
    state.sortModel = action.payload
    state.page = 0
  },
  setFilterModel(state: JDataGridState, action: PayloadAction<GridFilterModel>) {
    state.filterModel = action.payload
    state.page = 0
  },
  reload(state: JDataGridState) {
    state.reloadCounter += 1
  }
}

// This is needed because the query language does not use the same column names as the entities
function mapFieldNamesForJMC<T extends GridFilterItem | GridSortItem>(item: T): T {
  const name = "field" in item ? "field" : "columnField"
  if (item[name as keyof (GridSortItem | GridFilterItem)] === "creationDate") {
    return {
      ...item,
      [name]: "created"
    }
  } else if (["lastModificationDate", "updateDate"].includes(item[name as keyof (GridSortItem | GridFilterItem)])) {
    return {
      ...item,
      [name]: "modified"
    }
  } else if (item[name as keyof (GridSortItem | GridFilterItem)] === "startDate") {
    return {
      ...item,
      [name]: "started"
    }
  } else if (item[name as keyof (GridSortItem | GridFilterItem)] === "finishDate") {
    return {
      ...item,
      [name]: "finished"
    }
  } else if (item[name as keyof (GridSortItem | GridFilterItem)] === "processId") {
    return {
      ...item,
      [name]: "process"
    }
  }

  return item
}

export function mapGridSortItemForJMC(sortItem: GridSortItem): GridSortItem {
  return mapFieldNamesForJMC(sortItem)
}

function getDateAtMidnightInLocalTime(Ymd: string): Date {
  const m = Ymd.match(/^(\d{4})-(\d{2})-(\d{2})$/)
  if (m) {
    return new Date(Number(m[1]), Number(m[2]) - 1, Number(m[3]))
  } else {
    throw new Error(`Cannot parse '${Ymd}' as Y-m-d date`)
  }
}

export function mapGridFilterItemForJMC(filterItem: GridFilterItem): GridFilterItem {
  const op = filterItem.operatorValue
  const mappedFilterItem = mapFieldNamesForJMC(filterItem)
  if (op === "contains") {
    return {
      ...mappedFilterItem,
      operatorValue: "like"
    }
  } else if (op === "equals") {
    return {
      ...mappedFilterItem,
      operatorValue: "="
    }
  } else if (op === "inequals") {
    return {
      ...mappedFilterItem,
      operatorValue: "!="
    }
  } else if (op === "before") {
    return {
      ...mappedFilterItem,
      operatorValue: "<",
      // This transformation allows to compare times in a way that makes sense to the user,
      // in the local context of their browser.
      // Example: 2023-03-11 @ midnight (GMT-05) corresponds to 2023-03-11 @ 5:00 (UTC)
      value: getDateAtMidnightInLocalTime(filterItem.value).toISOString()
    }
  } else if (op === "after") {
    return {
      ...mappedFilterItem,
      operatorValue: ">",
      // This transformation allows to compare times in a way that makes sense to the user,
      // in the local context of their browser.
      // Example: 2023-03-11 @ midnight (GMT-05) corresponds to 2023-03-11 @ 5:00 (UTC)
      value: getDateAtMidnightInLocalTime(filterItem.value).toISOString()
    }
  } else if (op === "is") {
    return {
      ...mappedFilterItem,
      operatorValue: "="
    }
  } else if (op === "not") {
    return {
      ...mappedFilterItem,
      operatorValue: "!="
    }
  } else if (op === "isAnyOf") {
    return {
      ...mappedFilterItem,
      operatorValue: "in"
    }
  }
  return mappedFilterItem
}

export function getGridDateOperatorsForJMC(): GridFilterOperator[] {
  return getGridDateOperators().filter(op => ["before", "after"].includes(op.value))
}

export function getGridStringOperatorsForJMC(): GridFilterOperator[] {
  const stringFilterOperators = getGridStringOperators().filter(op => ["contains", "equals", "startsWith"].includes(op.value))
  // This hack is needed to customize the set of operators that are associated by default with
  // a text column. Since the default set does not contain an "inequals" operator, the simplest
  // solution is to simply relabel an existing one ("startsWith"), which works in our context
  // because the filtering is performed on the server anyway (the underlying filtering function
  // is not used). The alternative of creating a custom operator is less good, because we would
  // need to also supply custom localization values for the label, which would make it
  // unnecessarily complicated.
  return stringFilterOperators.map(op => (op.value === "startsWith" ? { ...op, value: "inequals", label: translate("label.is.not.equal") } : op))
}

export function getGridIdOperatorsForJMC(): GridFilterOperator[] {
  const idFilterOperators = getGridStringOperators().filter(op => ["equals", "startsWith"].includes(op.value))
  // See explanation for getGridStringOperatorsForJMC
  return idFilterOperators.map(op => (op.value === "startsWith" ? { ...op, value: "inequals", label: translate("label.is.not.equal") } : op))
}

export function getFormattedGridQueryForJMC(filterModel: GridFilterModel, sortModel: GridSortModel, quickFilterField?: string): string {
  // WHERE clause (predicates can only be ANDed for the moment)
  const predicates = []

  if (filterModel.quickFilterValues && quickFilterField) {
    for (const filterItem of filterModel?.quickFilterValues) {
      if (!filterItem) {
        continue
      }
      predicates.push(`${quickFilterField} like '${filterItem.replaceAll("'", "''")}'`)
    }
  }

  for (const filterItem of filterModel?.items ?? []) {
    if (!filterItem.value) {
      continue
    }
    let p = ""
    const fi = mapGridFilterItemForJMC(filterItem)
    if (Array.isArray(fi.value)) {
      if (fi.value.length > 0) {
        const values = fi.value.map(item => `'${item.replaceAll("'", "''")}'`).join(",")
        p = `${fi.columnField} ${fi.operatorValue} (${values})`
      }
    } else {
      p = `${fi.columnField} ${fi.operatorValue} '${fi.value.replaceAll("'", "''")}'`
    }
    predicates.push(p)
  }

  // ORDER BY clause
  const expressions = []
  for (const sortItem of sortModel) {
    const si = mapGridSortItemForJMC(sortItem)
    expressions.push(`${si.field} ${si.sort}`)
  }

  let q = ""
  if (predicates.length > 0) {
    // predicates are ANDed by default for the moment
    q += `${predicates.join(" and ")} `
  }

  if (expressions.length > 0) {
    q += `order by ${expressions.join(", ")}`
  }

  return q
}
