import { formCFG } from "form/config"

export function addPresetColor(hexaColor: string): void {
  if (formCFG.presetColors.indexOf(hexaColor) === -1) {
    formCFG.presetColors.push(hexaColor)
  }
}

export function getPresetColors(): string[] {
  return formCFG.presetColors.slice()
}
