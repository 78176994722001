import { Button, Dialog, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import React from "react"
import { useNavigate } from "react-router-dom"
import logoDark from "resources/images/logo-dark.png"
import logoLight from "resources/images/logo-light.png"

export const ErrorPage = ({ title, message }: { title: string; message: string }) => {
  const { isDarkTheme } = useAppSelector(state => ({ isDarkTheme: state.ui.theme.palette.mode === "dark" }))
  const navigate = useNavigate()

  return (
    <Dialog fullScreen open PaperProps={{ sx: { overflowY: "hidden" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          minHeight: "100vh"
        }}
      >
        <img
          src={isDarkTheme ? logoDark : logoLight}
          style={{
            maxWidth: "250px",
            maxHeight: "150px",
            height: "auto",
            width: "auto",
            marginRight: "2rem"
          }}
        />
        <Stack
          spacing={4}
          sx={{
            minHeight: "100%",
            minWidth: "30rem",
            maxWidth: "40rem",
            justifyContent: "center",
            alignItems: "flex-start"
          }}
        >
          <Typography variant="h6" fontSize={32}>
            {title}
          </Typography>
          <Typography fontSize={24}>{message}</Typography>
          <Button onClick={() => navigate("/")} color="primary" sx={{ borderRadius: 28 }}>
            {translate("label.home.page")}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  )
}
