import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import React from "react"

interface JDashboardDocumentationItemProps {
  mainText: string
  linkText: string
  linkUrl: string
}

export const DashboardDocumentationItem = (props: JDashboardDocumentationItemProps): JSX.Element => (
  <Card
    sx={theme => ({
      backgroundColor: theme.palette.primary.main
    })}
  >
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "right",
        padding: "2.4rem"
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: "1.5rem",
          width: "90%"
        }}
      >
        {props.mainText}
      </Typography>
    </CardContent>
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Button
        variant="text"
        sx={{ color: "white", fontSize: "1rem", justifyContent: "flex-end", margin: 0 }}
        onClick={() => window.open(props.linkUrl, "_blank")}
        endIcon={<FontAwesomeIcon className="dashboard-add-icon" icon={solid("right-long")} size="lg" />}
      >
        {props.linkText}
      </Button>
    </CardActions>
  </Card>
)
