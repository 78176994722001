export function getRouteBasePath(): string {
  return window.JMAP_OPTIONS?.urlBasePath || ""
}

export function parseBoolean(value: string | number | undefined): boolean | undefined {
  if (typeof value === "number" || typeof value === "undefined") {
    value = String(value)
  }
  switch (value.toLowerCase()) {
    case "true":
    case "1":
      return true
    case "false":
    case "0":
      return false
    default:
      return undefined
  }
}

export function isDate(date: any): boolean {
  return Object.prototype.toString.call(date) === "[object Date]"
}

export function getEmptyArrayIfNotDefined<T>(array: T[]): T[] {
  if (array === null || array === undefined) {
    return []
  }
  return array
}

export function isDefined(value: any): boolean {
  return value !== null && value !== undefined
}

export const objectHasValue = (object: any, value: any): boolean => {
  if (typeof object !== "object") {
    throw Error("Bad 'object' parameter : not an object !")
  }
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (object[key] === value) {
        return true
      }
    }
  }
  return false
}

export function capitalize(str: string): string {
  if (!str) {
    return str
  }
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export function isStringNumber(id: string | number): boolean {
  return Number.isInteger(Number(id))
}

export function isEmptyObject(object: any): boolean {
  return typeof object === "object" && JSON.stringify(object) === "{}"
}

export function deepEquals(element1: any, element2: any): boolean {
  if (typeof element1 !== typeof element2) {
    return false
  }
  if (typeof element1 !== "object" || element1 === null || element1 === undefined) {
    return element1 === element2
  }
  if (element2 === null || element2 === undefined) {
    return false
  }
  const element1Keys = Object.keys(element1)
  const element2Keys = Object.keys(element2)
  if (element1Keys.length !== element2Keys.length) {
    return false
  }
  for (const key of element1Keys) {
    // @recurive
    if (!deepEquals(element1[key], element2[key])) {
      return false
    }
  }
  return true
}
