import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Menu, MenuItem } from "@mui/material"
import React from "react"

export interface JButtonMenuItem {
  title: string
  disabled?: boolean
  isLoading?: boolean
  onClick: (params?: any) => any
}

export interface JButtonMenuProps {
  id: string
  disabled?: boolean
  items: JButtonMenuItem[]
  small?: boolean
  onClose?: () => void
  onClick?: () => void
}

export const ButtonMenu = (props: JButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
    props.onClose?.()
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id={props.id}
        aria-controls={open ? `${props.id}-menu` : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
          props.onClick?.()
        }}
      >
        <FontAwesomeIcon icon={regular("ellipsis-vertical")} size={props.small ? "sm" : "lg"} style={{ aspectRatio: "1 / 1" }} />
      </IconButton>
      <Menu
        id={`${props.id}-menu`}
        MenuListProps={{
          "aria-labelledby": props.id
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.items.map(item => (
          <MenuItem
            key={item.title}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
            disabled={props.disabled || item.disabled}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
