import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { translate } from "app/language/service"
import { formatDate } from "app/tool/date/tools/common"
import { JApiKey } from "organization/model"
import React from "react"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { RichTextLabelAdornmentWithCopyButton } from "ui/components/RichTextLabelAdornmentWithCopyButton"
import { TextLabel } from "ui/components/TextLabel"
import { getRoleChips } from "ui/utils"

interface JOrganizationApiKeyDetailsDialogProps {
  apikey: JApiKey
  onClose: () => any
}

export const OrganizationApiKeyDetailsDialog = (props: JOrganizationApiKeyDetailsDialogProps) => {
  const { apikey, onClose } = props
  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>{translate("organization.apikey.details.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextLabel value={apikey.title} label={translate("label.title")} />
              </Grid>
              <Grid item xs={6}>
                <RichTextLabel
                  label={translate("label.email")}
                  value={<RichTextLabelAdornmentWithCopyButton value={apikey.email} name={translate("label.email")} />}
                  InputProps={{
                    inputProps: { style: { overflow: "hidden", textOverflow: "ellipsis" } }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.createdBy")} value={apikey.createdBy} />
              </Grid>
              <Grid item xs={6}>
                <TextLabel label={translate("label.expiresAt")} value={apikey.expiresAt ? formatDate(apikey.expiresAt, { displayTime: true, longFormat: true }) : ""} />
              </Grid>
              <Grid item xs={12}>
                <RichTextLabel label={translate("label.roles")} value={getRoleChips(apikey.roles)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate("button.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}
