import { JDateService } from "./model"
import { dateAdd, dateSubstract, formatDate, formatDistanceToNow, getDate, getDateFnsLocale, getDateFromISOString, is12HoursTimeFormat, isDateAfter, isDateBefore } from "./tools/common"

export const dateSVC: JDateService = {
  getDate,
  getDateFnsLocale,
  is12HoursTimeFormat,
  getDateFromISOString,
  add: dateAdd,
  substract: dateSubstract,
  format: formatDate,
  formatDistanceToNow,
  isBefore: isDateBefore,
  isAfter: isDateAfter
}
