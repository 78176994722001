import { store } from "app/store/store"
import axios, { AxiosRequestConfig } from "axios"
import { EMPTY_TOKEN_VALUE } from "user/model"
import { getAccessToken, logout } from "user/tools/common"
import { JAjaxService, JRequestConfig } from "./model"

function logoutIfTokenInvalid(error: any): void {
  if (error?.response?.status === 401 && Array.isArray(error?.response?.errors) && error?.response?.errors[0].startsWith("The Token has expired")) {
    logout()
  }
}

function getAxiosRequestConfig(config?: JRequestConfig): AxiosRequestConfig {
  const axiosConfig: AxiosRequestConfig = {}
  axiosConfig.headers = {}
  axiosConfig.headers["x-auth-token"] = getAccessToken()
  if (config && config.params) {
    axiosConfig.params = config.params
  }
  const accessToken: string = store.getState().user.accessToken
  if (accessToken !== EMPTY_TOKEN_VALUE || config?.accessToken) {
    axiosConfig.headers.Authorization = config?.accessToken ? config.accessToken : accessToken
  }
  return axiosConfig
}

export const ajaxSVC: JAjaxService = {
  get(config: JRequestConfig): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(config.url, getAxiosRequestConfig(config))
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => {
          logoutIfTokenInvalid(error)
          reject(error)
        })
    })
  },
  post(config: JRequestConfig): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(config.url, config.params, getAxiosRequestConfig())
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => {
          logoutIfTokenInvalid(error)
          reject(error)
        })
    })
  },
  patch(config: JRequestConfig): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(config.url, config.params, getAxiosRequestConfig())
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => {
          logoutIfTokenInvalid(error)
          reject(error)
        })
    })
  },
  put(config: JRequestConfig): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(config.url, config.params, getAxiosRequestConfig())
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => {
          logoutIfTokenInvalid(error)
          reject(error)
        })
    })
  },
  del(config: JRequestConfig): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(config.url, getAxiosRequestConfig(config))
        .then((response: any) => {
          resolve(response.data)
        })
        .catch((error: any) => {
          logoutIfTokenInvalid(error)
          reject(error)
        })
    })
  }
}
