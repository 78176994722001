import { GridRowId } from "@mui/x-data-grid"
import React from "react"

export const useHoverableDataGridRows = () => {
  const [hoveredRowId, setHoveredRowId] = React.useState<GridRowId | null>(null)

  const onMouseEnter = (event: any) => {
    const id = event.currentTarget.getAttribute("data-id")
    setHoveredRowId(id)
  }

  const onMouseLeave = (event: any) => {
    setHoveredRowId(null)
  }

  const onMouseMove = (event: any) => {
    // The hover might have been disabled by clicking away from the dialog, in which case
    // it needs to be re-enabled in case we are still inside the row
    if (hoveredRowId === null) {
      const id = event.currentTarget.getAttribute("data-id")
      setHoveredRowId(id)
    }
  }

  return { hoveredRowId, setHoveredRowId, onMouseEnter, onMouseLeave, onMouseMove }
}
